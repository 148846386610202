import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';

import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo';
const PrivacyPolicy = () => {
  return (
    <>
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--10 mb_sm--0">
              <div className="col-lg-12">
                <h2>Polityka Prywatności</h2>
                <div align="left">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <strong>
                              <span>PL</span>
                            </strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>Wersja 1.0</span>
                          </p>
                          <p>
                            <span>Ostatnia aktualizacja: 17.01.2023</span>
                          </p>
                          <p>
                            <span>Obowiązuje od: 01.02.2023</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>
                              <span>Polityka prywatności</span>
                            </strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Niniejsza Polityka prywatności została opracowana
                              przez INSEE SOLUTIONS SP. ZOO z&nbsp;siedzibą we
                              Wrocławiu przy ul. Al. Śląska 1 , 54-118 Wrocław,
                              Polska (dalej &bdquo;INSEE SOLUTIONS&rdquo; lub
                              &bdquo;My&rdquo;)
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Kontakt z nami jest możliwy pod adresem
                              e-mail:&nbsp;
                            </span>
                            <a href="mailto:biuro@inseesolutions.eu">
                              <u>
                                <span>biuro@inseesolutions.eu</span>
                              </u>
                            </a>
                          </p>
                          <p>
                            <span>
                              lub korespondencyjnie pod adresem: INSEE SOLUTIONS
                              Sp.J. Al. Śląska 1 ,54-118 Wrocław, Polska&nbsp;
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>
                              <span>APLIKACJA</span>
                            </strong>
                            <span>
                              &nbsp;- oznacza aplikację Mousee dostarczaną przez
                              Usługodawcę
                            </span>
                          </p>
                          <p>
                            <strong>
                              <span>RODO</span>
                            </strong>
                            <span>
                              &nbsp;- oznacza Rozporządzenie Parlamentu
                              Europejskiego i Rady (UE) 2016/679 z dnia 27
                              kwietnia 2016 r. w sprawie ochrony os&oacute;b
                              fizycznych w związku z przetwarzaniem danych
                              osobowych i w sprawie swobodnego przepływu takich
                              danych oraz uchylenia dyrektywy 95/46/WE (Dz. Urz.
                              UE L 119 z 04.05.2016, str. 1, ze zmianą ogłoszoną
                              w Dz. Urz. UE L 127 z 23.05.2018, str. 2)
                            </span>
                          </p>
                          <p>
                            <strong>
                              <span>USŁUGODAWCA -</span>
                            </strong>
                            <span>
                              &nbsp;oznacza INSEE SOLUTIONS SP. ZOO z siedzibą
                              we Wrocławiu
                            </span>
                          </p>
                          <p>
                            <strong>
                              <span>UŻYTKOWNIK</span>
                            </strong>
                            <span>&nbsp;</span>
                            <em>
                              <span>(</span>
                            </em>
                            <strong>
                              <em>
                                <span>usługobiorca</span>
                              </em>
                            </strong>
                            <em>
                              <span>)</span>
                            </em>
                            <span>
                              &nbsp;- oznacza pełnoletnią osobę fizyczną, osobę
                              prawną albo jednostkę organizacyjną nieposiadającą
                              osobowości prawnej, kt&oacute;ra korzysta z
                              aplikacji
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Jeżeli w niniejszej polityce użyto termin&oacute;w
                              zdefiniowanych w rozporządzeniu RODO, terminy te
                              mają takie samo znaczenie jak w tym rozporządzeniu
                            </span>
                          </p>
                          <p>
                            <span>Klauzule zawarte w niniejszej polityce</span>
                            <span>
                              <sup>
                                <span>&nbsp;</span>
                              </sup>
                            </span>
                            <span>
                              odczytuje się i interpretuje w świetle
                              przepis&oacute;w rozporządzenia RODO.
                            </span>
                          </p>
                          <p>
                            <span>Klauzul zawartych w niniejszej polityce</span>
                            <span>
                              <sup>
                                <span>&nbsp;</span>
                              </sup>
                            </span>
                            <span>
                              nie interpretuje się w spos&oacute;b sprzeczny z
                              prawami i obowiązkami przewidzianymi
                              w&nbsp;rozporządzeniu RODO ani w spos&oacute;b
                              naruszający podstawowe prawa lub wolności
                              os&oacute;b, kt&oacute;rych dane dotyczą.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Z aplikacji korzystać może każda osoba,
                              kt&oacute;ra:
                            </span>
                          </p>
                          <ul>
                            <li>
                              <p>
                                <span>
                                  posiada konto w systemie (podała sw&oacute;j
                                  adres e-mail i wyraziła zgodę na przetwarzanie
                                  jej danych osobowych (adresu e-mail))
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  spełnia wymagania techniczne określone
                                  poniżej&nbsp;
                                </span>
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Aplikacja gromadzi wykorzystuje tylko te dane,
                              kt&oacute;re są wymagane do wykonania
                              odpowiedniego zadania / przetwarza dane w celu
                              umożliwienia użytkownikowi:
                            </span>
                          </p>
                          <ul>
                            <li>
                              <p>
                                <span>
                                  kontrolowania proces&oacute;w zwalczania i
                                  monitoringu szkodnik&oacute;w w czasie
                                  rzeczywistym
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  zarządzania urządzeniami dostępnymi na
                                  monitorowanym obiekcie
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>przeprowadzania inwentaryzacji</span>
                                <span>&nbsp;urządzeń</span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>generowania raport&oacute;w</span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  monitorowania powiadomień oraz stanu baterii
                                </span>
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <em>
                              <span>
                                Aplikacja zbiera / uzyskuje dostęp&nbsp;
                              </span>
                            </em>
                            <strong>
                              <em>
                                <span>
                                  wyłącznie do danych istotnych dla podstawowej
                                  funkcjonalności aplikacji
                                </span>
                              </em>
                            </strong>
                            <em>
                              <span>&nbsp;takich jak:</span>
                            </em>
                          </p>
                          <ul>
                            <li>
                              <strong>
                                <ul>
                                  <li>
                                    <p>
                                      <span>
                                        adres e-mail użytkownika&nbsp;
                                      </span>
                                      <span>
                                        (niezbędny do uwierzytelnienia
                                        użytkownika)
                                      </span>
                                    </p>
                                  </li>
                                </ul>
                              </strong>
                              <strong>
                                <ul>
                                  <li>
                                    <p>
                                      <span>
                                        model/rodzaj urządzenia użytkownika
                                      </span>
                                    </p>
                                  </li>
                                </ul>
                              </strong>
                              <strong>
                                <ul>
                                  <li>
                                    <p>
                                      <span>
                                        system operacyjny urządzenia użytkownika
                                      </span>
                                    </p>
                                  </li>
                                </ul>
                              </strong>
                              <strong>
                                <ul>
                                  <li>
                                    <p>
                                      <span>język urządzenia użytkownika</span>
                                    </p>
                                  </li>
                                </ul>
                              </strong>
                              <strong>
                                <ul>
                                  <li>
                                    <p>
                                      <span>
                                        rozdzielczość ekranu urządzenia
                                        użytkownika
                                      </span>
                                    </p>
                                  </li>
                                </ul>
                              </strong>
                              <strong>
                                <ul>
                                  <li>
                                    <p>
                                      <span>
                                        dane o ilości wykrytych
                                        szkodnik&oacute;w
                                      </span>
                                    </p>
                                  </li>
                                </ul>
                              </strong>
                              <strong>
                                <ul>
                                  <li>
                                    <p>
                                      <span>
                                        przechowywanie plik&oacute;w cookies lub
                                        stosowanie innych podobnych technologii
                                        w urządzeniu końcowym użytkownika
                                      </span>
                                    </p>
                                  </li>
                                </ul>
                              </strong>
                              <strong>
                                <ul>
                                  <li>
                                    <p>
                                      <span>
                                        aplikacja może uzyskać dostęp do zdjęć
                                        na urządzeniu użytkownika&nbsp;
                                      </span>
                                      <span>
                                        (dostęp do zdjęć potrzebny jest w celu
                                        robienia zdjęć podczas przeglądu
                                        technika na obiekcie)
                                      </span>
                                    </p>
                                  </li>
                                </ul>
                              </strong>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Co do zasady dane zbierane są automatycznie. Dane
                              o ilości wykrytych szkodnik&oacute;w mogą być
                              wprowadzane ręcznie przez użytkownika dokonującego
                              przeglądu na obiekcie. Login użytkownika (adres
                              e-mail) pozyskujemy od użytkownika lub od
                              podmiotu, z kt&oacute;rym użytkownik
                              wsp&oacute;łpracuje.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Podanie tych danych jest wymogiem umownym, a ich
                              niepodanie lub cofnięcie zgody na ich
                              przetwarzanie skutkować będzie brakiem możliwości
                              korzystania z aplikacji.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Administratorem przetwarzanych w aplikacji danych
                              osobowych jest usługodawca.&nbsp;
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Co do zasady podstawę prawną do przetwarzania
                              danych osobowych stanowi art. 6 ust. 1 lit. a)
                              RODO tj. &bdquo;
                            </span>
                            <strong>
                              <span>zgoda</span>
                            </strong>
                            <span>
                              &nbsp;osoby, kt&oacute;rej dane dotyczą&rdquo;
                              (&bdquo;oświadczenie woli&rdquo; lub
                              &bdquo;wyraźne działanie potwierdzające&rdquo;)
                            </span>
                          </p>
                          <p>
                            <span>
                              W określonych sytuacjach podstawę prawną do
                              przetwarzania danych osobowych może stanowić inna
                              samodzielna przesłanka legalizująca przetwarzanie:
                            </span>
                          </p>
                          <ul>
                            <li>
                              <p>
                                <span>
                                  art. 6 ust. 1 lit. b) RODO tj.
                                  &bdquo;przetwarzanie jest niezbędne do&nbsp;
                                </span>
                                <strong>
                                  <span>wykonania umowy</span>
                                </strong>
                                <span>
                                  , kt&oacute;rej stroną jest osoba,
                                  kt&oacute;rej dane dotyczą, lub do podjęcia
                                  działań na żądanie osoby, kt&oacute;rej dane
                                  dotyczą, przed zawarciem umowy&rdquo;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  art. 6 ust. 1 lit. c) RODO tj.
                                  &bdquo;przetwarzanie jest niezbędne do
                                  wypełnienia&nbsp;
                                </span>
                                <strong>
                                  <span>obowiązku prawnego</span>
                                </strong>
                                <span>
                                  &nbsp;ciążącego na administratorze&rdquo;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  art. 6 ust. 1 lit. f) RODO tj.
                                  &bdquo;przetwarzanie w celach wynikających
                                  z&nbsp;
                                </span>
                                <strong>
                                  <span>
                                    prawnie uzasadnionych interes&oacute;w
                                  </span>
                                </strong>
                                <span>
                                  &nbsp;realizowanych przez Administratora lub
                                  przez stronę trzecią&rdquo; (w
                                  szczeg&oacute;lności, ale nie wyłącznie, w
                                  celu dochodzenia roszczeń lub ochrony przed
                                  roszczeniami czy przetwarzania danych w ramach
                                  Grupy przedsiębiorstw do kt&oacute;rej należy
                                  Administrator, jak r&oacute;wnież w celach
                                  archiwalnych, bezpieczeństwa, w zakresie
                                  rozpatrywania ewentualnych skarg lub żądań
                                  oraz spełnienia zadość zasadzie
                                  rozliczalności, o kt&oacute;rej mowa w art. 5
                                  ust. 2 RODO).
                                </span>
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>Zgodnie z RODO&nbsp;</span>
                            <span>
                              każda osoba, kt&oacute;rej dane osobowe
                              przetwarzamy&nbsp;
                            </span>
                            <span>
                              ma prawo w dowolnym momencie wycofać zgodę, ale
                              wycofanie zgody &bdquo;nie wpływa na zgodność z
                              prawem przetwarzania, kt&oacute;rego dokonano na
                              podstawie zgody przed jej wycofaniem&rdquo;.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Dane osobowe będą przetwarzane (przechowywane)
                              odpowiednio - w zależności do tego co nastąpi
                              p&oacute;źniej- do czasu wycofania zgody na ich
                              przetwarzanie, do czasu aktywności konta, do czasu
                              utrzymywania aplikacji przez Usługodawcę, lub
                              przez okres przedawnienia roszczeń wynikający z
                              przepis&oacute;w prawa (co do zasady 3 lata,
                              maksymalnie 6 lat liczonych od dnia zakończenia
                              wsp&oacute;łpracy) - z zastrzeżeniem, że
                              informacje o wyrażeniu zgody na przetwarzanie
                              danych osobowych będą przechowywane bezterminowo w
                              celu spełnienia zadość zasadzie rozliczalności,
                              o&nbsp;kt&oacute;rej mowa w art. 5 ust. 2 RODO.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Dane osobowe mogą być ujawnione, wyłącznie w
                              zakresie w jakim jest to niezbędne i właściwe, dla
                              osiągnięcia cel&oacute;w przetwarzania i w oparciu
                              o zapisy umowy lub przepisy, następującym
                              kategoriom odbiorc&oacute;w:
                            </span>
                          </p>
                          <ul>
                            <li>
                              <p>
                                <span>
                                  podmioty świadczące Administratorowi usługi
                                  pomocy technicznej i dostawcy rozwiązań
                                  informatycznych umożliwiających prowadzenie
                                  Administratorowi działalności (np. dostawcy
                                  oprogramowania, dostawcy poczty elektronicznej
                                  i hostingu),
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  pracownicy i wsp&oacute;łpracownicy
                                  Administratora, i podmiot&oacute;w w Grupie
                                  przedsiębiorstw, do kt&oacute;rej należy
                                  Administrator
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  podmioty świadczące Administratorowi usługi
                                  doradcze i pomocy prawnej,&nbsp;
                                </span>
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Każdej osobie, kt&oacute;rej dane osobowe
                              przetwarzamy przysługuje prawo dostępu do danych
                              osobowych, ich sprostowania, usunięcia lub
                              ograniczenia ich przetwarzania,&nbsp;
                            </span>
                            <span>
                              wniesienia sprzeciwu wobec przetwarzania, a także
                              prawo do przenoszenia danych. Z przepis&oacute;w z
                              zakresu ochrony danych osobowych m.in. z RODO
                              wynika w jakim zakresie z każdego z tych praw
                              można skorzystać. Zależeć to będzie w
                              szczeg&oacute;lności od podstawy prawnej i celu
                              przetwarzania danych osobowych przez
                              Administratora.
                            </span>
                          </p>
                          <p>
                            <span>
                              Każda osoba, kt&oacute;rej dane osobowe
                              przetwarzamy&nbsp;
                            </span>
                            <span>
                              ma też prawo wniesienia skargi do organu
                              nadzorczego tj. Prezesa Urzędu Ochrony Danych
                              Osobowych.
                            </span>
                          </p>
                          <p>
                            <span>
                              W przypadku chęci skorzystania z tych uprawnień
                              (jak r&oacute;wnież w przypadku żądania
                              przeniesienia lub usunięcia konta)&nbsp;
                            </span>
                            <span>
                              osoba, kt&oacute;rej dane osobowe przetwarzamy
                            </span>
                            <span>
                              &nbsp;powinna skontaktować się z nami poprzez
                              e-mail&nbsp;
                            </span>
                            <a href="mailto:biuro@inseesolutions.eu">
                              <u>
                                <span>biuro@inseesolutions.eu</span>
                              </u>
                            </a>
                          </p>
                          <p>
                            <span>
                              Więcej informacji na naszej stronie internetowej w
                              zakładce Polityka Prywatności&nbsp;
                            </span>
                            <a href="https://inseesolutions.eu/polityka-prywatnosci/">
                              <u>
                                <span>
                                  https://inseesolutions.eu/polityka-prywatnosci/
                                </span>
                              </u>
                            </a>
                            <u>
                              <span>.</span>
                            </u>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Bezpieczeństwo danych jest dla nas ważne dlatego
                              stosujemy środki techniczne i organizacyjne w celu
                              zapewnienia bezpieczeństwa danych:&nbsp;
                            </span>
                          </p>
                          <ol>
                            <li>
                              <p>
                                <span>
                                  Środki umożliwiające pseudonimizację i
                                  szyfrowanie danych osobowych&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki zapewniające zdolność do ciągłego
                                  zapewnienia poufności, integralności,
                                  dostępności i odporności system&oacute;w i
                                  usług przetwarzania
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki zapewniające zdolność do szybkiego
                                  przywr&oacute;cenia dostępności danych
                                  osobowych i dostępu do nich w razie incydentu
                                  fizycznego lub technicznego&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Procesy umożliwiające regularne testowanie,
                                  mierzenie i ocenianie skuteczności
                                  środk&oacute;w technicznych i organizacyjnych
                                  mających zapewnić bezpieczeństwo
                                  przetwarzania&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki umożliwiające identyfikację i
                                  autoryzację użytkownik&oacute;w&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki zapewniające ochronę danych w czasie
                                  ich przekazywania&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki zapewniające ochronę danych w czasie
                                  ich przechowywania&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki służące zapewnieniu bezpieczeństwa
                                  fizycznego miejsc, w kt&oacute;rych
                                  przetwarzane są dane osobowe&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki umożliwiające rejestrowanie
                                  zdarzeń&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki służące do konfiguracji systemu, w tym
                                  konfiguracji domyślnej&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki dotyczące zarządzania wewnętrznym
                                  systemem IT i bezpieczeństwem IT&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki zapewniające minimalizację danych&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki zapewniające odpowiednią jakość
                                  danych&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki zapewniające ograniczone zatrzymywanie
                                  danych&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki zapewniające rozliczalność&nbsp;
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  Środki umożliwiające przenoszenie danych i
                                  zapewnienie ich usuwania.
                                </span>
                              </p>
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Więcej informacji o sposobach zabezpieczenia
                              danych można uzyskać kontaktując się z nami.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              W celu korzystania z aplikacji / platformy
                              niezbędne jest&nbsp;
                            </span>
                          </p>
                          <ul>
                            <li>
                              <p>
                                <span>
                                  posiadanie urządzenia końcowego (np. komputer
                                  PC, laptop, tablet, telefon, smartfon, smart
                                  TV) oraz
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  dostęp użytkownika (usługobiorcy) do sieci
                                  Internet oraz
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  posiadanie adresu e-mail (adresu
                                  elektronicznego);
                                </span>
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Wypełniając ustawowy obowiązek informujemy,
                              że&nbsp;
                            </span>
                            <span>
                              podczas korzystania z aplikacji zakazane jest:
                            </span>
                          </p>
                          <ul>
                            <li>
                              <p>
                                <span>
                                  dostarczanie nam przez nadawcę (użytkownika),
                                  treści o charakterze bezprawnym
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  wykorzystywanie komunikacji do przeprowadzania
                                  atak&oacute;w
                                </span>
                                <span>
                                  &nbsp;na nasze zasoby informatyczne firmy
                                </span>
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>Jeśli&nbsp;</span>
                            <span>użytkownik&nbsp;</span>
                            <span>
                              naruszy niniejszą Politykę, a my nie podejmiemy
                              natychmiastowych działań, to w żadnej mierze nie
                              oznacza to zrzeczenia się przez nas naszych praw,
                              w tym nie ogranicza naszego prawa do podjęcia
                              działań w przyszłości.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Wypełniając ustawowy obowiązek informujemy o
                              potencjalnych zagrożeniach związanych z
                              korzystaniem z każdej usługi świadczonej drogą
                              elektroniczną, kt&oacute;re mogą wystąpić pomimo
                              stosowania przez nas środk&oacute;w
                              zabezpieczających - są to w szczeg&oacute;lności,
                              ale nie wyłącznie: złośliwe oprogramowanie
                              r&oacute;żnego typu, programy szpiegujące, spam,
                              włamania do systemu teleinformatycznego przez
                              haker&oacute;w, możliwość bycia narażonym na
                              cracking lub phishing (
                            </span>
                            <span>
                              wiadomości z prośbą o ujawnienie hasła, np. w celu
                              &bdquo;zweryfikowania konta&rdquo; lub innego
                              typu&nbsp;
                            </span>
                            <span>
                              pr&oacute;ba nielegalnego pozyskania poufnych
                              danych z wykorzystaniem poczty elektronicznej
                              i/lub strony internetowej, w kt&oacute;rej
                              sprawca&nbsp;
                            </span>
                            <span>
                              podszywa się pod inną osobę lub instytucję
                            </span>
                            <span>
                              &nbsp;i/lub prezentuje się jako legalna firma czy
                              osoba ciesząca się dobrą opinią).&nbsp;
                            </span>
                          </p>
                          <p>
                            <span>
                              Użytkownik powinien brać pod uwagę, że żadna
                              metoda transmisji przez Internet ani metoda
                              przechowywania elektronicznego nie jest w 100%
                              bezpieczna.
                            </span>
                          </p>
                          <p>
                            <span>
                              Użytkownik może minimalizować ryzyko zagrożeń
                              poprzez stosowanie środk&oacute;w technicznych
                              adekwatnych do tego ryzyka w szczeg&oacute;lności,
                              ale nie wyłącznie, poprzez: dokonywanie
                              aktualizacji oprogramowania, włączoną zaporę
                              sieciową, posiadanie program&oacute;w
                              antywirusowych, regularne skanowanie komputera
                              programem antywirusowym, korzystanie z legalnego
                              oprogramowania posiadającego aktualne wsparcie
                              producenta oprogramowania, czytanie okien
                              instalacyjnych, ostrożne zachowanie w sieci
                              Internet, używanie r&oacute;żnych haseł w
                              r&oacute;żnych serwisach oraz nie logowanie się za
                              pomocą urządzeń nie pozostających pod jego
                              kontrolą (na urządzeniu może być zainstalowany
                              &bdquo;keylogger&rdquo; rejestrujący klawisze
                              wciskane przez użytkownika).
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Informacje, kt&oacute;re dostarczamy są
                              dostarczane &bdquo;tak jak są&rdquo; wyłącznie w
                              celach informacyjnych. &nbsp;W żadnym wypadku nie
                              ponosimy odpowiedzialności z tytułu jakichkolwiek
                              ewentualnych szk&oacute;d, bezpośrednich czy
                              pośrednich, niezależnie od tego czy owe szkody
                              były następstwem zaniedbania, błędu czy braku
                              właściwej informacji
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Wszystkie zgłoszone uwagi dotyczące aplikacji będą
                              analizowane przez nasz dział techniczny.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              W przypadku przekazania nam informacji zwrotnej
                              lub pomysł&oacute;w, użytkownik upoważnia nas do
                              wykorzystania informacji zwrotnej w dowolnym celu,
                              nieodpłatnie i bez żadnych ograniczeń.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Reklamację można złożyć w formie pisemnej pocztą
                              elektroniczną na adres&nbsp;
                            </span>
                            <a href="mailto:biuro@inseesolutions.eu">
                              <u>
                                <span>biuro@inseesolutions.eu</span>
                              </u>
                            </a>
                            <u>
                              <span>,&nbsp;</span>
                            </u>
                            <span>
                              listem poleconym, lub osobiście w naszej siedzibie
                            </span>
                          </p>
                          <p>
                            <span>
                              Zgłoszenie reklamacyjne powinno zawierać:
                            </span>
                          </p>
                          <p>
                            <span>
                              a) Nazwę/Imię i&nbsp;Nazwisko zgłaszającego
                            </span>
                          </p>
                          <p>
                            <span>b) Dane kontaktowe zgłaszającego</span>
                          </p>
                          <p>
                            <span>c) Pow&oacute;d reklamacji&nbsp;</span>
                          </p>
                          <p>
                            <span>
                              d) Opis okoliczności uzasadniających złożenie
                              reklamacji
                            </span>
                          </p>
                          <p>
                            <span>
                              Rozpatrzymy reklamację niezwłocznie ale nie
                              p&oacute;źniej niż w&nbsp;ciągu 14 dni licząc od
                              momentu jej otrzymania. W&nbsp;uzasadnionych
                              przypadkach czas rozpatrzenia reklamacji może ulec
                              wydłużeniu do 30 dni, o czym zgłaszający zostanie
                              poinformowany.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Prawem właściwym dla rozwiązywania spor&oacute;w
                              dotyczących niniejszej Polityki prywatności jest
                              prawo Rzeczpospolitej Polskiej, a sądami, sądy
                              powszechne w Rzeczpospolitej Polskiej.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Jeżeli kt&oacute;rekolwiek postanowienie
                              niniejszej Polityki, z mocy prawa lub ostatecznego
                              albo prawomocnego orzeczenia jakiegokolwiek organu
                              administracyjnego lub sądu, zostanie uznane za
                              nieważne lub nieskuteczne, pozostałe postanowienia
                              pozostaną w pełni skuteczne.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <span>
                              Zastrzegamy sobie prawo do zmiany niniejszej
                              Polityki w dowolnym momencie według własnego
                              uznania. Powiadomimy o wszelkich zmianach,
                              publikując nową Politykę Prywatności.
                              Zaktualizujemy datę &quot;ostatniej
                              aktualizacji&quot; na g&oacute;rze niniejszej
                              Polityki.
                            </span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>
                  <br />
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <CalltoActionTwo />
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default PrivacyPolicy;
