import React from 'react';
import {
  FiMap,
  FiBarChart,
  FiFolder,
  FiPhone,
  FiWifi,
  FiCalendar,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    list: [
      {
        title: 'Czy można robić przegląd wieloosobowy?',
        description:
          'Aktualnie nie ma takiej możliwości, ale pracujemy nad tym, aby ją wprowadzić w <br /><strong>3 kwartale roku 2024</strong>.',
      },
      {
        title: 'Czy dodatkowi technicy/użytkownicy są płatni?',
        description:
          'Nie, dodawanie nowych techników i użytkowników <strong>jest darmowe</strong>. Możesz dodawać ich w dowolnej ilości.',
      },
      {
        title: 'Czy mogę poprosić o dostosowanie aplikacji do moich potrzeb?',
        description:
          'Tak, możesz poprosić o dostosowanie aplikacji do swoich potrzeb, skontaktuj się z nami. Jesteśmy otwarci na wdrażanie nowych funkcji.',
      },
      {
        title: 'Kiedy mogę zrezynować z usługi?',
        description:
          'Możesz zrezygnować z usługi w dowolnym momencie. Okres wypowiedzenia wynosi <br /><strong>1 miesiąc</strong>.',
      },
      {
        title:
          'W jakim czasie mogę otrzymać detektory zdalnej detekcji Mousee?',
        description:
          'Na wstępie prosimy o kontakt i podanie ilości potrzebnych detektorów. Po podpisaniu umowy, detektory zostaną wysłane w ciągu <strong>14 dni roboczych</strong>.',
      },
      {
        title: 'Czy istnieje możliwość przetestowania aplikacji przed zakupem?',
        description:
          'Tak, istnieje możliwość przetestowania aplikacji przed zakupem. Skontaktuj się z nami, a udostępnimy Ci dostęp do testów dla 1 klienta.',
      },
    ],
  },
  en: {
    list: [
      {
        icon: <FiBarChart />,
        title: 'Analytics',
        description:
          'Analyzing trends of actions taken and pests caught will help eliminate the problem from each facility',
      },
      {
        icon: <FiFolder />,
        title: 'Reports',
        description:
          'Manually creating a report during an object inspection is cumbersome - the Insee platform generates a report automatically after the object inspection is completed in the Insee application.',
      },
      {
        icon: <FiMap />,
        title: 'Interactive object projection',
        description:
          'Upload an object projection, place all devices monitoring the object on it. Your client has a constant view of the exact locations of all control points.',
      },
      {
        icon: <FiCalendar />,
        title: 'Schedule',
        description:
          'Plan inspections and actions in the Insee application using the calendar. This way you will not forget about any object and you will always have a view of the schedule.',
      },
      {
        icon: <FiPhone />,
        title: 'Mobile application',
        description:
          'Our mobile application is available in the Android Play Store and iOS App Store. With it, you can scan QR and NFC codes and deliver information about devices faster',
      },
      {
        icon: <FiWifi />,
        title: 'Electronic devices',
        description:
          'Install electronic devices in the facility that will monitor the facility 24/7. Thanks to them, you will be able to quickly respond to the appearance of pests.',
      },
    ],
  },
};

const FAQ = ({ textAlign, serviceStyle }) => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="row row--15 service-wrapper">
      {data.list.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} left`}>
              <div className="content">
                <h5 className="title w-600">
                  <Link
                    to="#service"
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h5>
                <p
                  className="description  color-gray mb--0"
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default FAQ;
