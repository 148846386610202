import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    title: 'Sukces wymaga poświęcenia',
    description:
      'Ponad 10000 godzin, poświęconych stworzeniu naszego produktu Mousee, platformy web i aplikacji mobilnej. Przeanalizowanie aktualnie działających firm na rynku pomogło nam wdrożyć odpowiednie rozwiązania ‍ Partnerstwo: Nasz zespół stawia duży nacisk na kontakt z naszymi kontrahentami. Staramy się odpowiadać jak najszybciej na zadane pytania, jak i również rozwiązywać problemy jak najszybciej jest to możliwe',
    list: [
      {
        countNum: 100,
        isPercentage: true,
        countTitle: 'Satysfakcji klientów',
      },
      {
        countNum: 1500,
        countTitle: 'Urządzeń',
      },
      {
        countNum: 20,
        countTitle: 'Klientów na całym świecie',
      },
      {
        countNum: 100,
        isPercentage: true,
        countTitle: 'Zaangażowania',
      },
    ],
  },
  en: {
    title: 'Success requires sacrifice',
    description:
      'Over 10000 hours, dedicated to creating our Mousee product, web platform and mobile app. Analyzing currently operating companies on the market helped us implement the right solutions ‍ Partnership: Our team places great emphasis on contact with our contractors. We try to respond as quickly as possible to the questions asked, as well as to solve problems as quickly as possible',
    list: [
      {
        countNum: 100,
        isPercentage: true,
        countTitle: 'Customer satisfaction',
      },
      {
        countNum: 1500,
        countTitle: 'Devices',
      },
      {
        countNum: 20,
        countTitle: 'Customers worldwide',
      },
      {
        countNum: 100,
        isPercentage: true,
        countTitle: 'Engagement',
      },
    ],
  },
};

const SlipThree = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style bg-color-blackest">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail">
              <img src="/images/team2.png" alt="Zespół" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
              </ScrollAnimation>

              <div className="row">
                {data.list.map((data, index) => (
                  <div
                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                    key={index}
                  >
                    <div className="count-box counter-style-4 text-start">
                      <TrackVisibility once>
                        {({ isVisible }) =>
                          isVisible && (
                            <div className="count-number">
                              {isVisible ? <CountUp end={data.countNum} /> : 0}
                            </div>
                          )
                        }
                      </TrackVisibility>
                      <h5 className="counter-title">{data.countTitle}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SlipThree;
