import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    title: 'Przeglądy',
    description: (
      <>
        Nasza funkcjonalność Przeglądów to jak osobisty detektyw dla techników
        pest control. Umożliwia ona szybkie i dokładne sprawdzanie stanu pułapek
        i urządzeń w placówce poprzez skanowanie kodów QR lub tagów NFC. To
        proste i nowoczesne rozwiązanie sprawia, że przeglądanie i
        aktualizowanie danych o stanie urządzeń jest szybkie i bezbłędne.
      </>
    ),
    description2: 'Oto jak działa funkcjonalność Przeglądów:',
    list: [
      {
        title: 'Skanowanie',
        description:
          'Technik przychodzi do miejsca, gdzie jest pułapka lub sensor i skanuje kod QR lub tag NFC za pomocą urządzenia mobilnego.',
      },
      {
        title: 'Rejestracja akcji',
        description:
          'Po zeskanowaniu, w systemie od razu pojawiają się opcje dotyczące danej pułapki – czy trzeba ją wyczyścić, wymienić przynętę, czy też coś naprawić.',
      },
      {
        title: 'Zaznaczanie szkodników',
        description:
          'Jeśli w pułapce znajdują się szkodniki, technik może od razu zarejestrować ich typ i ilość, co jest zapisywane w systemie.',
      },
      {
        title: 'Raportowanie',
        description:
          'Wszystkie zebrane dane są automatycznie aktualizowane w profilu placówki, tworząc dokładny raport z przeglądu.',
      },
    ],
    description3:
      'Dzięki tej funkcjonalności, każdy przegląd jest dokładny i nic nie umknie uwadze technika. To oszczędza czas i pozwala na efektywniejsze zarządzanie pest control. Przeglądy stają się prostsze, a dane zebrane podczas nich są dokładne i łatwo dostępne. To świetne narzędzie dla firm, które chcą być pewne, że każdy szczegół ich pracy jest pod kontrolą.',
  },
  en: {
    title: 'Inspections',
    description: (
      <>
        Our Inspections functionality is like a personal detective for pest
        control technicians. It allows for quick and accurate checking of the
        state of traps and devices in the facility by scanning QR codes or NFC
        tags. This simple and modern solution makes browsing and updating device
        status data fast and error-free.
      </>
    ),
    description2: 'Here is how the Inspections functionality works:',
    list: [
      {
        title: 'Scanning',
        description:
          'The technician comes to the place where the trap or sensor is and scans the QR code or NFC tag using a mobile device.',
      },
      {
        title: 'Action registration',
        description:
          'After scanning, the system immediately displays options for the given trap – whether it needs to be cleaned, bait replaced, or something repaired.',
      },
      {
        title: 'Marking pests',
        description:
          'If there are pests in the trap, the technician can immediately register their type and quantity, which is saved in the system.',
      },
      {
        title: 'Reporting',
        description:
          'All collected data is automatically updated in the facility profile, creating an accurate inspection report.',
      },
    ],
    description3:
      'Thanks to this functionality, every inspection is accurate and nothing escapes the technician’s attention. This saves time and allows for more efficient pest control management. Inspections become simpler, and the data collected during them is accurate and easily accessible. This is a great tool for companies that want to be sure that every detail of their work is under control.',
  },
};

const InterventionsFunctionality = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style mb--60">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img src="/images/inspection.png" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  {data.description}
                  <br />
                  <br />
                  {data.description2}
                </p>
                <ul className="list-icon">
                  {data.list.map((item, index) => (
                    <li key={index}>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      <span>
                        <span className="theme-primary">{item.title}</span>:{' '}
                        {item.description}
                      </span>
                    </li>
                  ))}
                </ul>
                <p>{data.description3}</p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterventionsFunctionality;
