import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { ROUTES } from '../../utils/routes';
import { useTranslation } from '../../context/useTranslations';
import { Link } from 'react-router-dom/cjs/react-router-dom';
const translations = {
  pl: {
    title: 'Załóż darmowe konto ',
    subtitle:
      'Przetestuj aplikację przez 14 dni za darmo i przekonaj się, jak wiele możesz zyskać.',
    btnText: 'Darmowe konto',
    route: ROUTES.pl.contact,
  },
  en: {
    title: 'Create a free account',
    subtitle:
      'Test the application for 14 days for free and see how much you can gain.',
    btnText: 'Free account',
    route: ROUTES.en.contact,
  },
};

const CalltoActionEight = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div
      className="rn-callto-action rn-call-to-action style-8 content-wrapper mt-10"
      style={{ marginTop: 60 }}
    >
      <div className="container">
        <div className="row row--0 align-items-center ">
          <div className="col-lg-12">
            <div className="inner">
              <div className="content text-center">
                <h2 className="title">{data.title}</h2>
                <h6 className="subtitle">{data.subtitle}</h6>
                <div className="call-to-btn text-center mt--30">
                  <Link className="btn-default btn-icon" to={data.route.path}>
                    {data.btnText}{' '}
                    <i className="icon">
                      <FiArrowRight />
                    </i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionEight;
