export const HOME_TRANSLATIONS = {
  pl: {
    seo: {
      title: 'Oprogramowanie DDD - Insee Solutions',
      description:
        'Oprogramowanie dla firm z branży DDD (deratyzacji, dezynfekcji, dezynsekcji). Wierzymy, że dzięki naszym rozwiązaniom, firmy będą mogły zwiększyć efektywność swoich działań, a klienci będą mogli cieszyć się z lepszej jakości usług.',
      keywords:
        'aplikacja mobilna, aplikacja przeglądarkowa, urządzenia IOT, zarządzanie szkodnikami, DDD, deratyzacja, dezynfekcja, dezynsekcja, oprogramowanie dla firm, aplikacja dla firm, aplikacja dla firm DDD, aplikacja dla firm deratyzacyjnych, aplikacja dla firm dezynfekcyjnych, aplikacja dla firm dezynsekcyjnych, nfc, qrcode, qr, iot',
    },
    readMore: 'Czytaj więcej',
    topText: ' Aplikacja przeglądarkowa / Aplikacja mobilna / Urządzenia zdalnej detekcji',
    banner: {
      title: 'Aplikacja mobilna dla firm DDD',
      list: [
        `Oszczędzaj czas korzystając z <strong>aplikacji mobilnej.</strong>`,
        'Generuj automatyczne raporty w formacie PDF i XLSX.',
        'Zaplanuj przeglądy za pomocą wbudowanego kalendarza.',
        'Automatyczne rekomendacje podczas cyklicznych przeglądów',
        'Przegląd wieloosobowy (funkcjonalność w trakcie budowy)',
      ],
      button: 'Napisz do Nas',
    },
    mainProducts: {
      title: 'Główne produkty',
      subtitle: 'Wszystko czego potrzebujesz',
    },
    serviceArea: {
      title: 'Dostarczamy nowoczesne oprogramowanie dla firm z branży DDD',
      description: `Naszym celem jest dostarczenie nowoczesnego oprogramowania dla firm z branży DDD (deratyzacji, dezynfekcji, dezynsekcji). Wierzymy, że dzięki naszym rozwiązaniom, firmy będą mogły zwiększyć efektywność swoich działań, a klienci będą mogli cieszyć się z lepszej jakości usług.`,
    },
    features: {
      subtitle: 'Wszystko czego potrzebujesz',
      title: 'Funkcjonalności',
    },
  },
  en: {
    seo: {
      title: 'Pest Control software - Insee Solutions',
      description:
        'Software for companies from the pest control industry (deratization, disinfection, pest control). We believe that thanks to our solutions, companies will be able to increase the efficiency of their activities, and customers will be able to enjoy better quality services.',
      keywords:
        'mobile application, browser application, IOT devices, pest management, Pest control, deratization, disinfection, pest control, software for companies, application for companies, pest control application for companies, deratization application for companies, disinfection application for companies, pest control application for companies, nfc, qrcode, qr, iot',
    },
    readMore: 'Read more',
    topText: 'Browser application / Mobile application / IOT devices',
    banner: {
      title: 'Digital management of pest control',
      list: [
        'Save time using the mobile application.',
        'Generate automatic report in PDF and XLSX format.',
        'Plan inspections and necessary actions in the Insee application using the calendar.',
      ],
      button: 'Write to us',
    },
    mainProducts: {
      title: 'Main products',
      subtitle: 'Everything you need',
    },
    serviceArea: {
      title: 'We deliver modern software for companies from the pest control industry',
      description: `Our goal is to deliver modern software for companies from the pest control industry (deratization, disinfection, pest control). We believe that thanks to our solutions, companies will be able to increase the efficiency of their activities, and customers will be able to enjoy better quality services.`,
    },
    features: {
      subtitle: 'Everything you need',
      title: 'Our tools',
    },
  },
};
