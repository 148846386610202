import React from 'react';
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';
import ContactFormA from '../../screens/ContactFormA';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    phoneNumber: 'Numer telefonu',
    email: 'Adres Email',
    location: 'Lokalizacja',
  },
  en: {
    phoneNumber: 'Phone number',
    email: 'Email',
    location: 'Location',
  },
};

const ContactOne = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;

  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">{data.phoneNumber}</h4>
                    <p>
                      <a href="tel:+48 577 820 280">+48 577 820 280</a>
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">{data.email}</h4>
                    <p>
                      <a href="mailto:biuro@inseesolutions.eu">
                        biuro@inseesolutions.eu
                      </a>
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">{data.location}</h4>
                    <p>
                      Al. Śląska 1
                      <br /> 54-118 Wrocław, Polska
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactFormA formStyle="contact-form-1" />
          {/* <ContactForm formStyle="contact-form-1" /> */}
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30">
          {/* <GoogleMapStyle /> */}
        </div>
      </div>
    </>
  );
};
export default ContactOne;
