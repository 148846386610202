import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight } from 'react-icons/fi';
import Logo from '../logo/Logo';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from '../../context/useTranslations';
import { ROUTES } from '../../utils/routes';

const translations = {
  pl: {
    title: 'Ready to start creating a standard website?',
    subtitle:
      'Jesteśmy otwarci na sugestię i chętnie odpowiemy na każde pytanie. Jesteśmy w stanie dołożyć wszelkich starań, aby sprostać Państwa oczekiwaniom. Zapraszamy do kontaktu!',
    btnText: 'Skontaktuj się z Nami',
  },
  en: {
    title: 'Ready to start creating a standard website?',
    subtitle:
      'We are open to suggestions and will be happy to answer any questions. We are able to make every effort to meet your expectations. We invite you to contact us!',
    btnText: 'Contact us',
  },
};

const CalltoActionSeven = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-callto-action clltoaction-style-default style-7">
      <div className="container">
        <div className="row row--0 align-items-center content-wrapper">
          <div className="col-lg-8 col-md-8">
            <div className="inner">
              <div className="content text-start">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                  image2={`${process.env.PUBLIC_URL}/images/logo/logo-white.png`}
                />
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p className="subtitle">{data.subtitle}</p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className="call-to-btn text-start mt_sm--20 text-md-end">
                <Link className="btn-default btn-icon" to={ROUTES[translation.selectedLang].contact.path}>
                  {data.btnText}{' '}
                  <i className="icon">
                    <FiArrowRight />
                  </i>
                </Link>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionSeven;
