import React from 'react';
import SEO from '../../common/SEO';
import { FiCheck } from 'react-icons/fi';

import HeaderOne from '../../common/header/HeaderOne';
import FooterTwo from '../../common/footer/FooterTwo';
import Copyright from '../../common/footer/Copyright';

import ServiceOne from '../../elements/service/ServiceOne';
import Separator from '../../elements/separator/Separator';
import SectionTitle from '../../elements/sectionTitle/SectionTitle';

import WhatIsBrowserApp from './WhatIsBrowserApp';
import AnalyticsFunctionality from './AnalyticsFunctionality';
import InteractiveMapFunctionality from './InteractiveMapFunctionality';
import InterventionsFunctionality from './InterventionsFunctionality';
import CalendarFunctionality from './CalendarFunctionality';
import FilesFunctionality from './FilesFunctionality';
import ReportsFunctionality from './ReportsFunctionality';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    seo: {
      title: 'Aplikacja Przeglądarkowa - Insee Solutions',
      description:
        'Platforma Insee to nowoczesne oprogramowanie do zarządzania procesemi pest control, które oferuje kompleksowe rozwiązanie dla firm zabzpieczających obiekty przed szkodnikami.',
      keywords:
        'aplikacja mobilna, aplikacja przeglądarkowa, urządzenia IOT, zarządzanie szkodnikami, DDD, deratyzacja, dezynfekcja, dezynsekcja, oprogramowanie dla firm, aplikacja dla firm, aplikacja dla firm DDD, aplikacja dla firm deratyzacyjnych, aplikacja dla firm dezynfekcyjnych, aplikacja dla firm dezynsekcyjnych, nfc, qrcode, qr, iot',
    },
    smallTitle: 'Oprogramowanie w chmurze',
    title: 'Usprawnij swój proces',
    list: [
      {
        title: 'Nie twórz ręcznie raportów',
        description: 'Zaawansowane technologie w ochronie przed szkodnikami.',
      },
      {
        title: 'Koniec z papierologią',
        description: 'Inteligentne rozwiązania zapewniające ciągłą ochronę.',
      },
      {
        title: 'Trendy szkodników w czasie rzeczywistym',
        description: 'Skuteczne metody kontroli dostosowane do potrzeb.',
      },
    ],
    digital: {
      title: 'Cyfrowa Dokumentacja DDD',
      description:
        'Cyfrowa Dokumentacja DDD to rewolucja w branży deratyzacji, dezynfekcji i dezynsekcji, przenosząc cały proces dokumentowania interwencji do szybkiego i wydajnego środowiska online. Dzięki przechowywaniu danych w chmurze, wszelkie protokoły i raporty są dostępne w każdej chwili i z każdego miejsca, eliminując potrzebę stosowania papierowych formularzy. To nowoczesne rozwiązanie nie tylko przyspiesza pracę techników DDD, ale także zwiększa dokładność i bezpieczeństwo przechowywanych informacji.',
    },
    features: {
      subtitle: 'Przydatne Narzędzia',
      title: 'Wszystko czego potrzebujesz',
    },
  },
  en: {
    seo: {
      title: 'Browser App - Insee Solutions',
      description:
        'The Insee platform is modern software for managing pest control processes, which offers a comprehensive solution for companies securing objects against pests.',
      keywords:
        'mobile application, browser application, IOT devices, pest management, Pest control, deratization, disinfection, pest control, software for companies, application for companies, pest control application for companies, deratization application for companies, disinfection application for companies, pest control application for companies, nfc, qrcode, qr, iot',
    },
    smallTitle: 'Cloud Software',
    title: 'Improve your process',
    list: [
      {
        title: 'Do not create reports manually',
        description: 'Advanced technologies in pest control.',
      },
      {
        title: 'End of paperwork',
        description: 'Intelligent solutions providing continuous protection.',
      },
      {
        title: 'Pest trends in real time',
        description: 'Effective control methods tailored to your needs.',
      },
    ],
    digital: {
      title: 'Digital Pest Control Documentation',
      description:
        'Digital Pest Control Documentation is a revolution in the pest control industry, moving the entire intervention documentation process to a fast and efficient online environment. By storing data in the cloud, all protocols and reports are available at any time and from any place, eliminating the need for paper forms. This modern solution not only speeds up the work of pest control technicians, but also increases the accuracy and security of stored information.',
    },
    features: {
      subtitle: 'Useful Tools',
      title: 'Everything you need',
    },
  },
};

const BrowserApp = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <>
      <SEO
        title={data.seo.title}
        description={data.seo.description}
        keywords={data.seo.keywords}
      />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-750">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <span className="subtitle">{data.smallTitle}</span>
                  <h1 className="title theme-primary display-one">
                    {data.title}
                  </h1>
                  <ul className="list-icon">
                    {data.list.map((item, index) => (
                      <li key={index}>
                        <span className="icon">
                          <FiCheck />
                        </span>{' '}
                        {item.title}: {item.description}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-xl-6 order-1 order-lg-2">
                <div className="frame-image">
                  <img src="/images/beforeAfterInsee.png" alt="Insee" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <WhatIsBrowserApp />
        <AnalyticsFunctionality />
        <InterventionsFunctionality />
        <InteractiveMapFunctionality />
        <CalendarFunctionality />
        <FilesFunctionality />
        <ReportsFunctionality />

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle={data.features.subtitle}
                  title={data.features.title}
                  description=""
                />
              </div>
            </div>

            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}
        {/* Start Service Area  */}
        <div className="service-area rn-section-gapBottom mt--100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">{data.digital.title}</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">{data.digital.description}</p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default BrowserApp;
