import React, { useState } from 'react';
import { addDoc, collection } from '@firebase/firestore';
import { db } from './firebase'; // załóżmy, że tutaj inicjalizujesz Firestore
import { useTranslation } from '../context/useTranslations';

const translations = {
  pl: {
    phoneNumber: 'Numer telefonu',
    email: 'Adres Email (wymagane)',
    name: 'Imię i nazwisko lub nazwa firmy (wymagane)',
    message: 'Wiadomość (wymagane)',
    btn: 'Wyślij',
    successMessage:
      'Twoja wiadomość została wysłana. Niedługo się skontaktujemy',
    errorMessage:
      'Coś poszło nie tak. Napisz do Nas na email biuro@inseesolutions.eu lub zadzwoń +48 577 820 280',
  },
  en: {
    phoneNumber: 'Phone number',
    email: 'Email (required)',
    name: 'Name or company name (required)',
    message: 'Message (required)',
    btn: 'Send',
    successMessage: 'Your message has been sent. We will contact you soon',
    errorMessage:
      'Something went wrong. Write to us at biuro@inseesolutions.eu or call +48 577 820 280',
  },
};

const ContactFormA = ({ formStyle }) => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  const [result, setResult] = useState();
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const formRef = React.useRef();
  const handleSubmit = async (e) => {
    setResult();
    setError();
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'messages'), formData);
      console.log('Document written with ID: ', docRef.id);
      setResult(true);
      setFormData({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      });
      formRef.current?.reset();
    } catch (error) {
      setError(error);
      console.error('Error adding document: ', error);
    }
  };

  return (
    <form
      className={`${formStyle}`}
      action=""
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <div className="form-group">
        <label>{data.name}</label>
        <input type="text" name="name" required onChange={handleChange} />
      </div>

      <div className="form-group">
        <label>{data.email}</label>
        <input type="email" name="email" required onChange={handleChange} />
      </div>

      <div className="form-group">
        <label>{data.phoneNumber}</label>
        <input type="text" name="phone" onChange={handleChange} />
      </div>

      <div className="form-group">
        <label>{data.message}</label>
        <textarea name="message" required onChange={handleChange}></textarea>
      </div>

      <div className="form-group">
        <button className="btn-default btn-large">{data.btn}</button>
      </div>

      <div className="form-group">
        {result ? (
          <p className="success-message">{data.successMessage}</p>
        ) : null}
      </div>
      <div className="form-group">
        {error ? (
          <div className="error-message">{data.errorMessage}</div>
        ) : null}
      </div>
    </form>
  );
};

export default ContactFormA;
