import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    title: 'Raporty',
    description: (
      <>
        Nasza funkcja raportowania to jak osobisty analityk dla każdego technika
        pest control. Po każdej interwencji lub przeglądzie, system
        automatycznie przygotowuje kompleksowy raport, który można pobrać w
        formatach XLSX lub PDF. Dzięki temu, dokumentacja działań staje się
        prosta i przejrzysta, a kontrolerzy mogą łatwo zatwierdzić wykonaną
        pracę.
        <br />
        <br />
        Oto jak działa funkcjonalność raportowania:
      </>
    ),
    list: [
      {
        title: 'Automatyczne raporty:',
        description:
          'Bezpośrednio po zakończeniu interwencji lub przeglądu, system generuje raport, który zawiera wszystkie szczegółowe informacje o wykonanej pracy.',
      },
      {
        title: 'Elastyczność:',
        description:
          'Użytkownik może samodzielnie wybrać okres, za który chce wygenerować raport, co pozwala na dostosowanie dokumentacji do własnych potrzeb i wymagań klientów.',
      },
      {
        title: 'Formaty do wyboru:',
        description:
          'Raporty można pobrać jako pliki XLSX, które świetnie nadają się do dalszej analizy danych, lub jako pliki PDF, idealne do prezentacji czy archiwizacji.',
      },
      {
        title: 'Zatwierdzanie:',
        description:
          'Gotowe raporty są przesyłane do kontrolerów, którzy mogą je przejrzeć i zatwierdzić, co zapewnia dodatkowy poziom weryfikacji i jakości.',
      },
    ],
    description2: (
      <>
        Dzięki tej funkcji, zarówno technicy jak i kontrolerzy mają pewność, że
        wszystkie dane są dokładnie zarejestrowane i łatwo dostępne. To nie
        tylko upraszcza proces dokumentowania działań, ale także podnosi
        standardy usług pest control, gwarantując wysoką jakość i zadowolenie
        klientów.
      </>
    ),
  },
  en: {
    title: 'Reports',
    description: (
      <>
        Our reporting feature is like a personal analyst for every pest control
        technician. After each intervention or inspection, the system
        automatically prepares a comprehensive report that can be downloaded in
        XLSX or PDF formats. This makes the documentation of activities simple
        and transparent, and controllers can easily approve the work done.
        <br />
        <br />
        Here is how the reporting functionality works:
      </>
    ),
    list: [
      {
        title: 'Automatic reports:',
        description:
          'Immediately after the intervention or inspection, the system generates a report that contains all detailed information about the work done.',
      },
      {
        title: 'Flexibility:',
        description:
          'The user can choose the period for which they want to generate a report, which allows for adjusting the documentation to their own needs and the requirements of clients.',
      },
      {
        title: 'Formats to choose from:',
        description:
          'Reports can be downloaded as XLSX files, which are great for further data analysis, or as PDF files, perfect for presentation or archiving.',
      },
      {
        title: 'Approval:',
        description:
          'Ready reports are sent to controllers, who can review and approve them, providing an additional level of verification and quality.',
      },
    ],
    description2: (
      <>
        Thanks to this feature, both technicians and controllers can be sure
        that all data is accurately registered and easily accessible. This not
        only simplifies the process of documenting activities, but also raises
        the standards of pest control services, guaranteeing high quality and
        customer satisfaction.
      </>
    ),
  },
};

const ReportsFunctionality = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style mb--60">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img src="/images/reports.png" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
                <ul className="list-icon">
                  {data.list.map((item, index) => (
                    <li key={index}>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      <span>
                        <span className="theme-primary">{item.title}</span>{' '}
                        {item.description}
                      </span>
                    </li>
                  ))}
                </ul>
                <p>{data.description2}</p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsFunctionality;
