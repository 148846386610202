import React from 'react';
import SEO from '../common/SEO';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import { Link } from 'react-router-dom';
import HeaderOne from '../common/header/HeaderOne';
import AdvancePricingOne from '../elements/advancePricing/AdvancePricingOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';

import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo';

import PricingTwo from '../elements/pricing/PricingTwo';

const Pricing = () => {
  return (
    <>
      <SEO title="Cennik - Insee Solutions" />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--10 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Cennik"
                  title="Ile to kosztuje?"
                  description=""
                />
              </div>
            </div>
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12 text-center">
                Cennik nie dotyczy elektronicznych urządzeń{' '}
                <span className="theme-primary">
                  <Link to={'/mousee'}>Mousee</Link>
                </span>
                . <br />
                Odnosi się jedynie do aplikacji przeglądarkowej i mobilnej.
              </div>
            </div>
            <PricingTwo />
          </div>
        </div>
        {/* End Elements Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-pricingtable-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Cennik"
                  title="Najczęściej wybierany pakiet"
                  description=""
                />
              </div>
            </div>
            <div className="row mt--30">
              <div className="col-lg-8 offset-lg-2">
                <AdvancePricingOne />
              </div>
            </div>
          </div>
        </div>
        {/* End Elements Area  */}

        <CalltoActionTwo />
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default Pricing;
