import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import Copyright from '../../common/footer/Copyright';
import FooterTwo from '../../common/footer/FooterTwo';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import AboutFour from '../../elements/about/AboutFour';
import AboutOne from '../../elements/about/AboutOne';
import CalltoActionTwo from '../../elements/calltoaction/CalltoActionTwo';
import CounterUpFour from '../../elements/counterup/CounterUpFour';
import SectionTitle from '../../elements/sectionTitle/SectionTitle';
import Separator from '../../elements/separator/Separator';
import ServiceOne from '../../elements/service/ServiceOne';
import ServiceThree from '../../elements/service/ServiceThree';
import SlpitOne from '../../elements/split/SlpitOne';
import { useTranslation } from '../../context/useTranslations';
import { HOME_TRANSLATIONS } from './translations';
import { ROUTES } from '../../utils/routes';
import PricingTwo from '../../elements/pricing/PricingTwo';
import FAQ from '../../elements/service/FAQ';
import CalltoActionEight from '../../elements/calltoaction/CalltoActionEight';

const Home = () => {
  const translation = useTranslation();
  const translations =
    HOME_TRANSLATIONS[translation.selectedLang] || HOME_TRANSLATIONS.pl;
  return (
    <>
      <SEO
        title={translations.seo.title}
        description={translations.seo.description}
        keywords={translations.seo.keywords}
      />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-750">
          <div className="container">
            <div className="inner text-center  mb--80">
              <span className="subtitle">{translations.topText}</span>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-5 col-xl-5 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <h3
                    className="title display-one"
                    style={{
                      color: 'black',
                      lineHeight: 1.1,
                      fontSize: '6rem',
                    }}
                  >
                    {translations.banner.title}
                    {/* <br />{' '}
                    <span className="color-primary">szkodnikami DDD.</span> */}
                  </h3>
                  <ul className="list-icon">
                    {translations.banner.list.map((item, index) => (
                      <li key={index}>
                        <span className="icon">
                          <FiCheck />
                        </span>{' '}
                        <span dangerouslySetInnerHTML={{ __html: item }} />
                      </li>
                    ))}
                  </ul>
                  <div className="button-group mt--40">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.insee.mousee"
                      target="_blank"
                      rel="noreferrer noopener"
                      className=""
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/badges/google-play-badge.png`}
                        alt="Google play"
                        style={{
                          borderRadius: 0,
                          maxHeight: 55,
                          marginLeft: 0,
                        }}
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/pl/app/mousee-ochrona-ddd/id6444954617?itsct=apps_box_link&itscg=30200"
                      target="_blank"
                      rel="noreferrer noopener"
                      className=""
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/badges/app-store-badge.png`}
                        alt="App Store"
                        style={{
                          borderRadius: 0,
                          maxHeight: 55,
                          marginLeft: 0,
                        }}
                      />
                    </a>
                    {/* <Link
                      className="btn-default btn-medium round btn-icon"
                      to={ROUTES[translation.selectedLang].contact.path}
                    >
                      {translations.banner.button}
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-xl-7 order-1 order-lg-2 justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/home_main3.png`}
                    alt="Insee Solutions Warehouse"
                    style={{ borderRadius: 10, maxHeight: 850, marginLeft: 20 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Counterup Area  */}
        <div className="our-counterup-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* Start Elements Area  */}
                <div className="rwt-counterup-area ptb--60">
                  <div className="col-lg-12">
                    <CounterUpFour
                      column="col-lg-4 col-md-6 col-sm-6 col-12"
                      counterStyle="counter-style-4"
                      textALign="text-center"
                    />
                  </div>
                </div>
                {/* End Elements Area  */}
              </div>
            </div>
          </div>
        </div>
        {/* End Counterup Area  */}
        <SlpitOne />
        <CalltoActionEight />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle={translations.mainProducts.subtitle}
                  title={translations.mainProducts.title}
                  description=""
                />
              </div>
            </div>
            <ServiceThree
              translations={translations.mainProducts}
              serviceStyle="service__style--2"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />


        <AboutFour image="./images/about/contact-image.jpg" />
        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">{translations.serviceArea.title}</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">{translations.serviceArea.description}</p>
                <div className="readmore-btn">
                  <Link
                    className="btn-read-more"
                    to={ROUTES[translation.selectedLang].browserApp.path}
                  >
                    <span>{translations.readMore}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        <AboutOne />

        <PricingTwo />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gapTop mb--100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle={translations.features.subtitle}
                  title={translations.features.title}
                  description=""
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gapTop mb--100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle={"Kto pyta nie błądzi"}
                  title={"Najczęsciej zadawane pytania"}
                  description=""
                />
              </div>
            </div>
            <FAQ
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />
        <Separator />

        <CalltoActionTwo />
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default Home;
