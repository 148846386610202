import { useLanguage } from './LanguageContext';
import { pl } from './pl';
import { en } from './en';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

export const useTranslation = () => {
  const { language, toggleLanguage } = useLanguage();
  const location = useLocation();

  const selectedLang = useMemo(() => {
    const x = location.pathname.split('/');
    const possibleLangs = ['pl', 'en'];
    if (x && x[1] && possibleLangs.includes(x[1])) {
      return x[1];
    }
    return 'pl';
  }, [location.pathname]);

  useEffect(() => {
    toggleLanguage(selectedLang);
  }, [selectedLang, toggleLanguage]);

  const t = (key) => {
    const translations = language === 'pl' ? pl : en;
    return translations[key] || key;
  };

  return { t, selectedLang };
};
