import React from 'react';
import SEO from '../common/SEO';
import HeaderOne from '../common/header/HeaderOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import TeamFour from '../elements/team/TeamFour';
import TimelineTwo from '../elements/timeline/TimelineTwo';
import SlipThree from '../elements/split/SlipThree';
import FooterTwo from '../common/footer/FooterTwo';
import { useTranslation } from '../context/useTranslations';

const translations = {
  pl: {
    seo: {
      title: 'O nas - Insee Solutions',
      description:
        'W naszej firmie łączymy innowacyjność z ekspertyzą, oferując przyszłościowe rozwiązania w branży pest control, które gwarantują bezpieczeństwo i czystość na najwyższym poziomie.',
      keywords:
        'aplikacja mobilna, aplikacja przeglądarkowa, urządzenia IOT, zarządzanie szkodnikami, DDD, deratyzacja, dezynfekcja, dezynsekcja, oprogramowanie dla firm, aplikacja dla firm, aplikacja dla firm DDD, aplikacja dla firm deratyzacyjnych, aplikacja dla firm dezynfekcyjnych, aplikacja dla firm dezynsekcyjnych, nfc, qrcode, qr, iot',
    },
    smallTitle: 'Nasi Specjaliści',
    title: 'Nowa era w zwalczaniu szkodników.',
    description:
      'W naszej firmie łączymy innowacyjność z ekspertyzą, oferując przyszłościowe rozwiązania w branży pest control, które gwarantują bezpieczeństwo i czystość na najwyższym poziomie.',
    slogan: 'Szkodniki w odwrocie, innowacja w marszu',
    team: {
      subtitle: 'Nasz zespół',
      title: 'Odpowiedzialni za produkt',
    },
  },
  en: {
    seo: {
      title: 'About us - Insee Solutions',
      description:
        'In our company, we combine innovation with expertise, offering future-oriented solutions in the pest control industry that guarantee safety and cleanliness at the highest level.',
      keywords:
        'mobile application, browser application, IOT devices, pest management, Pest control, deratization, disinfection, pest control, software for companies, application for companies, pest control application for companies, deratization application for companies, disinfection application for companies, pest control application for companies, nfc, qrcode, qr, iot',
    },
    smallTitle: 'Our Specialists',
    title: 'A new era in pest control.',
    description:
      'In our company, we combine innovation with expertise, offering future-oriented solutions in the pest control industry that guarantee safety and cleanliness at the highest level.',
    slogan: 'Pests in retreat, innovation on the march',
    team: {
      subtitle: 'Our Team',
      title: 'Responsible for the product',
    },
  },
};

const AboutUs = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <>
      <SEO
        title={data.seo.title}
        description={data.seo.description}
        keywords={data.seo.keywords}
      />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/teambg.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-primary">{data.smallTitle}</span>
                    </h3>
                  </div>
                  <h1 className="title display-one">{data.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop mb--100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">{data.slogan}</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10"> {data.description}</p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        <SlipThree />
        <div className="rwt-timeline-area rn-section-gapBottom mt--100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div>

        {/* Start Elements Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle={data.team.subtitle}
                  title={data.team.title}
                  description=""
                />
              </div>
            </div>
            <TeamFour
              column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
              teamStyle="team-style-three"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterTwo />
      </main>
    </>
  );
};

export default AboutUs;
