import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from '../../context/useTranslations';
const translations = {
  pl: {
    title: 'Mousee Insects (w trakcie badań)',
    description:
      'Urządzenia Mousee to zaawansowane narzędzia monitorujące służące do inteligentnej kontroli szkodników, wykorzystujące protokół LoRaWAN, który zapewnia szerokie pokrycie oraz stabilne połączenie internetowe, nawet w dużych halach przemysłowych. Są one zaprojektowane do wykrywania i raportowania obecności gryzoni (Mousee One) oraz owadów (Mousee Insect) w pułapkach, co pozwala na szybką reakcję i efektywne zarządzanie pest control.',
    keyFeatures: 'Kluczowe cechy Mousee Insect:',
    list: [
      {
        icon: <FiCheck />,
        text: (
          <>
            <span className="theme-primary">Automatyczna analiza lepu: </span>{' '}
            Monitoruje stopień zapełnienia lepu, umożliwiając terminową wymianę.
          </>
        ),
      },
      {
        icon: <FiCheck />,
        text: (
          <>
            <span className="theme-primary">Rozpoznawanie owadów: </span> AI
            analizuje i identyfikuje rodzaje złapanych owadów, dostarczając
            cennych informacji o infestacji.
          </>
        ),
      },
      {
        icon: <FiCheck />,
        text: (
          <>
            <span className="theme-primary">Łatwe mocowanie: </span> Prosty w
            instalacji na ścianach w strategicznych punktach.
          </>
        ),
      },
      {
        icon: <FiCheck />,
        text: (
          <>
            <span className="theme-primary">Powiadomienia i raporty: </span>{' '}
            Generuje alerty i raporty o aktywności owadów, które można
            przeglądać przez aplikację.
          </>
        ),
      },
      {
        icon: <FiCheck />,
        text: (
          <>
            <span className="theme-primary">Eko-friendly: </span> Pomaga w
            redukcji stosowania chemikaliów poprzez precyzyjne targetowanie
            obszarów problemowych.
          </>
        ),
      },
    ],
    description2:
      'Dzięki tej funkcjonalności, każdy przegląd jest dokładny i nic nie umknie uwadze technika. To oszczędza czas i pozwala na efektywniejsze zarządzanie pest control. Przeglądy stają się prostsze, a dane zebrane podczas nich są dokładne i łatwo dostępne. To świetne narzędzie dla firm, które chcą być pewne, że każdy szczegół ich pracy jest pod kontrolą.',
  },
  en: {
    title: 'Mousee Insects (under development)',
    description:
      'Mousee devices are advanced monitoring tools for intelligent pest control, using the LoRaWAN protocol, which provides wide coverage and stable internet connection, even in large industrial halls. They are designed to detect and report the presence of rodents (Mousee One) and insects (Mousee Insect) in traps, allowing for a quick response and effective pest control management.',
    keyFeatures: 'Key features of Mousee Insect:',
    list: [
      {
        icon: <FiCheck />,
        text: (
          <>
            <span className="theme-primary">Automatic glue analysis: </span>{' '}
            Monitors the degree of glue filling, allowing for timely
            replacement.
          </>
        ),
      },
      {
        icon: <FiCheck />,
        text: (
          <>
            <span className="theme-primary">Insect recognition: </span> AI
            analyzes and identifies the types of trapped insects, providing
            valuable information about infestation.
          </>
        ),
      },
      {
        icon: <FiCheck />,
        text: (
          <>
            <span className="theme-primary">Easy mounting: </span> Easy to
            install on walls at strategic points.
          </>
        ),
      },
      {
        icon: <FiCheck />,
        text: (
          <>
            <span className="theme-primary">Notifications and reports: </span>{' '}
            Generates alerts and reports on insect activity, which can be viewed
            through the application.
          </>
        ),
      },
      {
        icon: <FiCheck />,
        text: (
          <>
            <span className="theme-primary">Eco-friendly: </span> Helps reduce
            the use of chemicals by precisely targeting problem areas.
          </>
        ),
      },
    ],
    description2:
      "Thanks to this functionality, each inspection is thorough and nothing escapes the technician's attention. This saves time and allows for more efficient pest control management. Inspections become simpler, and the data collected during them is accurate and easily accessible. This is a great tool for companies that want to be sure that every detail of their work is under control.",
  },
};

const MouseeInsects = () => {
  const translation = useTranslation(translations);
  const data = translations[translation.selectedLang] || translations.pl;

  return (
    <div className="rn-splite-style mb--60">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img src="/images/mouseeinsect.png" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  {data.description}
                  <br /> <br />
                  {data.keyFeatures}
                </p>
                <ul className="list-icon">
                  {data.list.map((item, index) => (
                    <li key={index}>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      <span>
                        <span className="theme-primary">{item.title}</span>{' '}
                        {item.text}
                      </span>
                    </li>
                  ))}
                </ul>
                <p>{data.description2}</p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MouseeInsects;
