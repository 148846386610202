import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from '../../context/useTranslations';
import { ROUTES } from '../../utils/routes';

const translations = {
  pl: {
    title: 'Czym jest Insee?',
    description: (
      <>
        Insee to{' '}
        <span className="color-primary bold">nowoczesna aplikacja mobilna i komputerowa</span> do
        zarządzania procesami dla firm DDD(deratyzacja, dezynfekcja, dezynsekcja), które oferuje kompleksowe
        rozwiązanie dla firm zabzpieczających obiekty przed szkodnikami.
      </>
    ),
    description2: `System Insee oferuje najlepsze rozwiązanie przeciwko szkodnikom i składa się z oprogramowania biurowego, aplikacji mobilnej dla techników oraz portalu dla klientów. Możesz pracować jednocześnie w biurze, z kilkoma technikami w ich aplikacjach i z wieloma klientami w portalu.`,
    button: 'Skontaktuj się z Nami',
    route: ROUTES.pl.contact.path
  },
  en: {
    title: 'What is Insee?',
    description: `Insee is a modern software for protecting objects from pests, created to automate processes related to Pest Control and to adapt to current technological trends in the pest control industry.`,
    description2: `The Insee system offers the best solution against pests and consists of office software, applications for technicians and a portal for clients. You can work simultaneously in the office, with several technicians in their applications and with many clients in the portal.`,
    button: 'Contact Us',
    route: ROUTES.en.contact.path
  },
};

const SlpitOne = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img src="/images/mainpage.jpg" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p>{data.description2}</p>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <div className="view-more-button mt--35">
                  <Link className="btn-default" to={data.route}>
                    {data.button}
                  </Link>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlpitOne;
