import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from '../../context/useTranslations';
const translations = {
  pl: {
    title: 'Mousee One',
    description: (
      <>
        <span className="theme-primary">Mousee One</span> to innowacyjne
        urządzenie, które wykorzystuje inteligentne technologie do monitorowania
        żywołapek na gryzonie. Jest to niezawodny system, który informuje o
        złapanych szkodnikach, takich jak myszy czy szczury, w czasie
        rzeczywistym, ułatwiając kontrolę i zarządzanie plagą w sposób
        humanitarny i efektywny.
      </>
    ),
    keyFeatures: 'Kluczowe cechy Mousee One:',
    list: [
      {
        icon: <FiCheck />,
        title: 'Wykrywanie w czasie rzeczywistym:',
        description:
          'Informuje o obecności szkodników bez konieczności manualnego sprawdzania pułapek.',
      },
      {
        icon: <FiCheck />,
        title: 'Powiadomienia mobilne:',
        description:
          'Wysyła alert na telefon lub urządzenie mobilne, gdy szkodnik zostanie złapany.',
      },
      {
        icon: <FiCheck />,
        title: 'Humanitarna kontrola:',
        description:
          'Umożliwia szybką reakcję, minimalizując stres i cierpienie schwytanych zwierząt.',
      },
      {
        icon: <FiCheck />,
        title: 'Prosta instalacja:',
        description: 'Łatwy w montażu i konfiguracji w różnych środowiskach.',
      },
      {
        icon: <FiCheck />,
        title: 'Zintegrowane zarządzanie:',
        description:
          'Współpracuje z aplikacją lub platformą do zarządzania pest control, centralizując dane.',
      },
    ],
  },
  en: {
    title: 'Mousee One',
    description: (
      <>
        <span className="theme-primary">Mousee One</span> is an innovative device
        that uses intelligent technologies to monitor live traps for rodents. It
        is a reliable system that informs about trapped pests, such as mice or
        rats, in real time, facilitating humane and effective control and
        management of the plague.
      </>
    ),
    keyFeatures: 'Key features of Mousee One:',
    list: [
      {
        icon: <FiCheck />,
        title: 'Real-time detection:',
        description:
          'Informs about the presence of pests without the need for manual trap checking.',
      },
      {
        icon: <FiCheck />,
        title: 'Mobile notifications:',
        description:
          'Sends an alert to your phone or mobile device when a pest is trapped.',
      },
      {
        icon: <FiCheck />,
        title: 'Humane control:',
        description:
          'Enables a quick response, minimizing the stress and suffering of trapped animals.',
      },
      {
        icon: <FiCheck />,
        title: 'Easy installation:',
        description: 'Easy to install and configure in various environments.',
      },
      {
        icon: <FiCheck />,
        title: 'Integrated management:',
        description:
          'Works with a pest control management application or platform, centralizing data.',
      },
    ],
  },
};
const MouseeOne = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style mb--60">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  {data.description}
                  <br />
                  <br />
                  {data.keyFeatures}
                </p>
                <ul className="list-icon">
                  {data.list.map((item, index) => (
                    <li key={index}>
                      <span className="icon">{item.icon}</span>
                      <span>
                        <span className="theme-primary">{item.title}</span>:{' '}
                        {item.description}
                      </span>
                    </li>
                  ))}
                </ul>
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-right-content">
              <img src="/images/mousee1.jpg" alt="split Images" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MouseeOne;
