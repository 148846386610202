import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from '../../context/useTranslations';
const translations = {
  pl: {
    title: 'Uniwersalność',
    description:
      'Urządzenia Mousee stanowią uniwersalne rozwiązanie, zaprojektowane tak, aby pasować do każdego modelu pułapki i żywołapki dostępnej na rynku. Ich adaptacyjność zapewnia łatwość w implementacji oraz skuteczność w działaniu, co czyni je idealnym wyborem dla różnorodnych środowisk i sytuacji pest control. Bez względu na markę czy rodzaj pułapki, urządzenia Mousee można łatwo zintegrować, co sprawia, że zarządzanie szkodnikami staje się prostsze i bardziej efektywne.',
    list: [
      {
        icon: <FiCheck />,
        title: 'Uniwersalność',
        description:
          'Pasuje do każdego modelu pułapki i żywołapki, co sprawia, że są kompatybilne z szeroką gamą produktów na rynku.',
      },
      {
        icon: <FiCheck />,
        title: 'Łatwa integracja',
        description:
          'Bezproblemowe dopasowanie do istniejących systemów pest control, minimalizując potrzebę zakupu nowego sprzętu.',
      },
      {
        icon: <FiCheck />,
        title: 'Skuteczne rozwiązanie',
        description:
          'Umożliwiają wykrywanie i reagowanie na obecność szkodników w sposób szybki i precyzyjny.',
      },
      {
        icon: <FiCheck />,
        title: 'Elastyczność w użyciu',
        description:
          'Nadają się do różnorodnych zastosowań, od małych pomieszczeń po duże przestrzenie przemysłowe.',
      },
    ],
  },
  en: {
    title: 'Versatility',
    description:
      'Mousee devices are a universal solution, designed to fit into any trap or live trap available on the market. Their adaptability ensures ease of implementation and effectiveness, making them an ideal choice for a variety of pest control environments and situations. Regardless of the brand or type of trap, Mousee devices can be easily integrated, making pest management simpler and more efficient.',
    list: [
      {
        icon: <FiCheck />,
        title: 'Versatility',
        description:
          'Fits into any trap or live trap, making them compatible with a wide range of products on the market.',
      },
      {
        icon: <FiCheck />,
        title: 'Easy integration',
        description:
          'Seamless fit into existing pest control systems, minimizing the need to purchase new equipment.',
      },
      {
        icon: <FiCheck />,
        title: 'Effective solution',
        description:
          'Enables quick and precise detection and response to the presence of pests.',
      },
      {
        icon: <FiCheck />,
        title: 'Flexibility in use',
        description:
          'Suitable for a variety of applications, from small rooms to large industrial spaces.',
      },
    ],
  },
};

const MouseeUniversal = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style mb--60">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
                <ul className="list-icon">
                  {data.list.map((item, index) => (
                    <li key={index}>
                      <span className="icon">{item.icon}</span>
                      <span>
                        <span className="theme-primary">{item.title}</span>{' '}
                        {item.description}
                      </span>
                    </li>
                  ))}
                </ul>
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-right-content">
              <img src="/images/mouseeUniversal.png" alt="split Images" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MouseeUniversal;
