import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from '../../context/useTranslations';

export const translations = {
  pl: {
    list: [
      {
        image: '/images/desktop_new2.png',
        title: 'Aplikacja przeglądarkowa',
        description:
          'W pełni funkcjonalna aplikacja przeglądarkowa, która pozwala na zarządzanie wszystkimi procesami w firmie pest control. Dostępna na każdym urządzeniu z dostępem do internetu.',
      },
      {
        image: '/images/phone_new.png',
        title: 'Aplikacja mobilna',
        description:
          'Aplikacja mobilna na systemy Android i iOS, która pozwala na przeprowadzanie przeglądów w terenie. Skanuj kody QR i NFC w celu przyspieszenia przeprowadzenia działań w placówce.',
      },
      {
        image: '/images/mouseeUniversal.png',
        title: 'Zdalne detekory Mousee',
        description:
          'Urządzenia IOT, które pozwalają na automatyzację procesów w firmie pest control. Skanuj kody QR i NFC w celu przyspieszenia przeprowadzenia działań w placówce.',
      },
    ],
  },
  en: {
    list: [
      {
        image: '/images/desktop3.png',
        title: 'Browser application',
        description:
          'A fully functional browser application that allows you to manage all pest control company processes. Available on any device with internet access.',
      },
      {
        image: '/images/phone1.png',
        title: 'Mobile application',
        description:
          'A mobile application for Android and iOS systems that allows you to conduct inspections in the field. Scan QR and NFC codes to expedite actions in the facility.',
      },
      {
        image: '/images/device1.png',
        title: 'Mousee electronic devices',
        description:
          'IOT devices that allow you to automate processes in a pest control company. Scan QR and NFC codes to expedite actions in the facility.',
      },
    ],
  },
};

const ServiceThree = ({ textAlign, serviceStyle }) => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="row row--15 service-wrapper">
      {data.list.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image">
                  <img
                    src={`${val.image}`}
                    alt="card Images"
                    style={{ borderRadius: 5 }}
                  />
                </div>
                <div className="content">
                  <h4 className="title">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceThree;
