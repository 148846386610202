import React from 'react';
import { FiCheck } from 'react-icons/fi';
// import X from '../../assets/images/chart_comparison.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from '../../context/useTranslations';
import { ROUTES } from '../../utils/routes';
const translations = {
  pl: {
    title: (
      <>
        <strong>Jak to działa?</strong>
      </>
    ),
    text: 'Obejrzyj nasze 2 minutowe wideo instruktażowe, aby dowiedzieć się, jakie korzyści płyną z korzystania z aplikacji mobilnej Insee.',
    list: [
      {
        icon: <FiCheck />,
        title: 'Aplikacja mobilna:',
        text: 'Rób przeglądy w aplikacji mobilnej na systemach Android i iOS. Skanuj kody QR i NFC w celu przyspieszenia przeprowadzenia działań w placówce',
      },
      {
        icon: <FiCheck />,
        title: 'Aplikacja przeglądarkowa',
        text: 'Platforma Insee to cyfrowe narzędzie do zarządzania procesami pest control, które oferuje kompleksowe rozwiązanie dla firm zabezpieczających obiekty przed szkodnikami.',
      },
    ],
    aboutApp: 'O aplikacji',
  },
  en: {
    title: (
      <>
        Improve processes in the company <br />
        and increase efficiency <br />
        with <strong>Mobile Application</strong>
      </>
    ),
    list: [
      {
        icon: <FiCheck />,
        title: 'Mobile application:',
        text: 'Conduct inspections in the mobile application on Android and iOS systems. Scan QR and NFC codes to expedite actions in the facility.',
      },
      {
        icon: <FiCheck />,
        title: 'Browser application',
        text: 'The Insee platform is a digital tool for managing pest control processes that offers a comprehensive solution for companies securing facilities against pests.',
      },
    ],
    aboutApp: 'About App',
  },
};

const AboutFour = ({ image }) => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="about-area about-style-4 rn-section-gap">
      <div className="container">
        <div className="row row--40 align-items-center">
          <div className="col-lg-6">
            <iframe
              width="100%"
              height="515"
              src="https://www.youtube.com/embed/biZUjuAHJt0?si=XGh0m30WOEPC1IcU"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
            {/* <img src={X} style={{ borderRadius: 20 }} alt="Reports" /> */}
          </div>
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                <h3 className="title">{data.title}</h3>
                <p>{data.text}</p>
                <ul className="feature-list">
                  {data.list.map((item, index) => (
                    <li key={index}>
                      <div className="icon">
                        <FiCheck />
                      </div>
                      <div className="title-wrapper">
                        <h4 className="title">{item.title}</h4>
                        <p className="text">{item.text}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="about-btn mt--30">
                  <Link
                    className="btn-default"
                    to={ROUTES[translation.selectedLang].browserApp.path}
                  >
                    {data.aboutApp}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
