import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    title: 'Aplikacja przeglądarkowa',
    description: (
      <>
        {' '}
        Platforma Insee to{' '}
        <span className="color-primary bold">nowoczesna aplikacja mobilna i komputerowa</span> do
        zarządzania procesami dla firm DDD(deratyzacja, dezynfekcja, dezynsekcja), które oferuje kompleksowe
        rozwiązanie dla firm zabzpieczających obiekty przed szkodnikami.
      </>
    ),
    description2:
      'System Insee oferuje najlepsze rozwiązanie przeciwko szkodnikom i składa się z oprogramowania biurowego, aplikacji dla techników oraz portalu dla klientów. Możesz pracować jednocześnie w biurze, z kilkoma technikami w ich aplikacjach i z wieloma klientami w portalu.',
  },
  en: {
    title: 'Browser App',
    description: (
      <>
        {' '}
        The Insee platform is{' '}
        <span className="color-primary bold">modern software</span> for managing
        pest control processes, which offers a comprehensive solution for
        companies securing objects against pests.
      </>
    ),
    description2:
      'The Insee system offers the best solution against pests and consists of office software, applications for technicians and a portal for customers. You can work simultaneously in the office, with several technicians in their applications and with many clients in the portal.',
  },
};

const WhatIsBrowserApp = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style mb--60">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img src="/images/mainpage.jpg" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p>{data.description2}</p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsBrowserApp;
