import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    title: 'Czym są urządzenia Mousee?',
    description:
      'Urządzenia Mousee to zaawansowane narzędzia monitorujące służące do inteligentnej kontroli szkodników, wykorzystujące protokół LoRaWAN, który zapewnia szerokie pokrycie oraz stabilne połączenie internetowe, nawet w dużych halach przemysłowych. Są one zaprojektowane do wykrywania i raportowania obecności gryzoni (Mousee One) oraz owadów (Mousee Insect) w pułapkach, co pozwala na szybką reakcję i efektywne zarządzanie pest control.',
    list: [
      {
        icon: <FiCheck />,
        title: 'Długie zasięgi komunikacji',
        text: 'Dzięki LoRaWAN urządzenia działają niezawodnie nawet na dużych obszarach przemysłowych.',
      },
      {
        icon: <FiCheck />,
        title: 'Automatyczne powiadomienia',
        text: 'Technicy otrzymują alert gdy szkodnik zostanie wykryty, co umożliwia natychmiastową interwencję.',
      },
      {
        icon: <FiCheck />,
        title: 'Oszczędność czasu',
        text: 'Zmniejszają potrzebę manualnych przeglądów, automatyzując proces monitorowania.',
      },
      {
        icon: <FiCheck />,
        title: 'Precyzyjne danych',
        text: 'Zapewniają szczegółowe informacje o rodzaju i liczbie szkodników, co pozwala na dostosowanie strategii pest control.',
      },
      {
        icon: <FiCheck />,
        title: 'Łatwa instalacja i obsługa',
        text: 'Proste w montażu i konfiguracji, nie wymagają skomplikowanej obsługi.',
      },
      {
        icon: <FiCheck />,
        title: 'Energia i koszty',
        text: 'Są energooszczędne i redukują koszty związane z tradycyjnymi metodami monitorowania.',
      },
      {
        icon: <FiCheck />,
        title: 'Bezpieczne dla środowiska',
        text: 'Umożliwiają ograniczenie użycia środków chemicznych przez precyzyjne lokalizowanie problemów.',
      },
    ],
  },
  en: {
    title: 'What are Mousee devices?',
    description:
      'Mousee devices are advanced monitoring tools for intelligent pest control, using the LoRaWAN protocol, which provides wide coverage and stable internet connection, even in large industrial halls. They are designed to detect and report the presence of rodents (Mousee One) and insects (Mousee Insect) in traps, allowing for a quick response and effective pest control management.',
    list: [
      {
        icon: <FiCheck />,
        title: 'Long communication ranges',
        text: 'Thanks to LoRaWAN, the devices work reliably even in large industrial areas.',
      },
      {
        icon: <FiCheck />,
        title: 'Automatic notifications',
        text: 'Technicians receive an alert when a pest is detected, allowing for immediate intervention.',
      },
      {
        icon: <FiCheck />,
        title: 'Time saving',
        text: 'They reduce the need for manual inspections, automating the monitoring process.',
      },
      {
        icon: <FiCheck />,
        title: 'Precise data',
        text: 'They provide detailed information about the type and number of pests, allowing for the adaptation of pest control strategies.',
      },
      {
        icon: <FiCheck />,
        title: 'Easy installation and operation',
        text: 'Easy to install and configure, they do not require complicated operation.',
      },
      {
        icon: <FiCheck />,
        title: 'Energy and costs',
        text: 'They are energy-efficient and reduce costs associated with traditional monitoring methods.',
      },
      {
        icon: <FiCheck />,
        title: 'Environmentally friendly',
        text: 'They allow for the reduction of the use of chemical agents by precisely locating problems.',
      },
    ],
  },
};

const WhatIsBrowserApp = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style mb--60">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img src="/images/warehouseRadio.png" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <ul className="list-icon">
                  {data.list.map((item, index) => (
                    <li key={index}>
                      <span className="icon">{item.icon}</span>
                      <span>
                        <span className="theme-primary">{item.title}</span>:{' '}
                        {item.text}
                      </span>
                    </li>
                  ))}
                </ul>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsBrowserApp;
