import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from '../../context/useTranslations';
const translations = {
  pl: {
    title: 'Interaktywny Rzut Placówki',
    description:
      'Nasz interaktywny rzut placówki to jak inteligentny plan twojego budynku, który pokazuje, gdzie szkodniki najczęściej dają o sobie znać. Działa to trochę jak gra, gdzie na ekranie zobaczysz kolorowe punkty lub obszary pokazujące, gdzie szkodniki pojawiają się najczęściej, opierając się o informacje zebrane od użytkowników.',
    list: [
      {
        title: 'Mapa aktywności szkodników:',
        description:
          'Pokazuje ci, które miejsca w twoim budynku są najbardziej lubiane przez szkodniki, dzięki czemu wiesz, gdzie skupić swoją uwagę',
      },
      {
        title: 'Dokładny plan:',
        description:
          'Masz wyraźny obraz każdej części twojej przestrzeni pracy, więc łatwo znajdziesz każdą pułapkę czy sensor.',
      },
      {
        title: 'Znaczniki:',
        description:
          'Możesz oznaczyć konkretne miejsca na mapie, co pomoże ci śledzić, gdzie coś się wydarzyło lub co trzeba sprawdzić.',
      },
      {
        title: 'Aktualizacje na bieżąco:',
        description:
          'Jeśli coś się zmienia, możesz szybko zaktualizować mapę, więc zawsze masz aktualny obraz sytuacji.',
      },
      {
        title: 'Informacje w czasie rzeczywistym:',
        description:
          'Mapa jest na bieżąco odświeżana, więc masz najświeższe informacje o tym, co się dzieje.',
      },
    ],
  },
  en: {
    title: 'Interactive Map',
    description:
      'Our interactive map is like an intelligent plan of your building, showing where pests are most likely to make themselves known. It works a bit like a game, where you’ll see colorful dots or areas on the screen showing where pests are most likely to appear, based on information collected from users.',
    list: [
      {
        title: 'Pest activity map:',
        description:
          'Shows you which places in your building are most loved by pests, so you know where to focus your attention',
      },
      {
        title: 'Clear plan:',
        description:
          'You have a clear picture of every part of your workspace, so you can easily find every trap or sensor.',
      },
      {
        title: 'Markers:',
        description:
          'You can mark specific places on the map, which will help you track where something happened or what needs to be checked.',
      },
      {
        title: 'Real-time updates:',
        description:
          'If something changes, you can quickly update the map, so you always have an up-to-date picture of the situation.',
      },
      {
        title: 'Real-time information:',
        description:
          'The map is constantly refreshed, so you have the freshest information about what’s happening.',
      },
    ],
  },
};

const InteractiveMapFunctionality = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style mb--60">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
                <ul className="list-icon">
                  {data.list.map((item, index) => (
                    <li key={index}>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      <span>
                        <span className="theme-primary">{item.title}</span>{' '}
                        {item.description}
                      </span>
                    </li>
                  ))}
                </ul>
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-right-content">
              <img src="/images/interactiveMap.png" alt="split Images" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteractiveMapFunctionality;
