import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop';

import './assets/scss/style.scss';
import Page404 from './screens/Page404';
import { LanguageProvider } from './context/LanguageContext';
import AboutUs from './screens/AboutUs';
import Home from './screens/Home';
import PrivacyPolicy from './screens/PrivacyPolicy';
import BrowserApp from './screens/BrowserApp';
import Contact from './screens/Contact';
import Pricing from './screens/Pricing';
import Mousee from './screens/Mousee';
import { ROUTES } from './utils/routes';

const App = () => {
  return (
    <LanguageProvider>
      <PageScrollTop>
        <Switch>
          {/* {map(routes, (route) => {
            return (
              <Route
                key={route.path}
                path={`${process.env.PUBLIC_URL + route.path}`}
                exact
                component={route.component}
              />
            );
          })} */}
          <Route path={ROUTES.pl.home.path} exact component={Home} />
          <Route path={ROUTES.pl.aboutUs.path} exact component={AboutUs} />
          <Route path={ROUTES.pl.contact.path} exact component={Contact} />
          <Route path={ROUTES.pl.pricing.path} exact component={Pricing} />
          <Route path={ROUTES.pl.mousee.path} exact component={Mousee} />
          {/* <Route path={ROUTES.pl.platform.path} exact component={Contact} /> */}
          {/* <Route path={ROUTES.pl.mobileApp.path} exact component={Contact} /> */}
          <Route
            path={ROUTES.pl.browserApp.path}
            exact
            component={BrowserApp}
          />
          <Route
            path={ROUTES.pl.privacyPolicy.path}
            exact
            component={PrivacyPolicy}
          />

          <Route path={ROUTES.en.home.path} exact component={Home} />
          <Route path={ROUTES.en.aboutUs.path} exact component={AboutUs} />
          <Route path={ROUTES.en.contact.path} exact component={Contact} />
          <Route path={ROUTES.en.pricing.path} exact component={Pricing} />
          <Route path={ROUTES.en.mousee.path} exact component={Mousee} />
          {/* <Route path={ROUTES.en.platform.path} exact component={Contact} /> */}
          {/* <Route path={ROUTES.en.mobileApp.path} exact component={Contact} /> */}
          <Route
            path={ROUTES.en.browserApp.path}
            exact
            component={BrowserApp}
          />
          <Route
            path={ROUTES.en.privacyPolicy.path}
            exact
            component={PrivacyPolicy}
          />

          {/* {map(ROUTES, (langRoutes, lang) => {
            return map(langRoutes, (route) => {
              return (
                <Route
                  key={route.path}
                  path={`${process.env.PUBLIC_URL + route.path}`}
                  exact
                  component={route.component}
                />
              );
            });
          })} */}

          <Route path={`en/*`} exact component={Page404} />
          <Route path={`*`} exact component={Page404} />
        </Switch>
      </PageScrollTop>
    </LanguageProvider>
  );
};

export default App;
