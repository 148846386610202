export const ROUTES = {
  pl: {
    home: {
      path: '/',
    },
    aboutUs: {
      path: '/o-nas',
      menuTitle: 'O nas',
    },
    contact: {
      path: '/kontakt',
      menuTitle: 'Kontakt',
    },
    pricing: {
      path: '/cennik',
      menuTitle: 'Cennik',
    },
    mousee: {
      path: '/mousee',
      menuTitle: 'Zdalne detektory',
    },
    platform: {
      path: '/platforma',
      menuTitle: 'Platforma',
    },
    mobileApp: {
      path: '/aplikacja-mobilna',
      menuTitle: 'Aplikacja mobilna',
    },
    browserApp: {
      path: '/aplikacja-przegladarkowa',
      menuTitle: 'Aplikacja przeglądarkowa',
    },
    privacyPolicy: {
      path: '/polityka-prywatnosci',
      menuTitle: 'Polityka prywatności',
    },
  },
  en: {
    privacyPolicy: {
      path: '/en/privacy-policy',
      menuTitle: 'Privacy Policy',
    },
    home: {
      path: '/en',
      menuTitle: 'Insee Solutions',
    },
    aboutUs: {
      path: '/en/about-us',
      menuTitle: 'About Us',
    },
    contact: {
      path: '/en/contact',
      menuTitle: 'Contact',
    },
    pricing: {
      path: '/en/pricing',
      menuTitle: 'Pricing',
    },
    mousee: {
      path: '/en/mousee',
      menuTitle: 'Remote detection',
    },
    platform: {
      path: '/en/platform',
      menuTitle: 'Platform',
    },
    mobileApp: {
      path: '/en/mobile-app',
      menuTitle: 'Mobile App',
    },
    browserApp: {
      path: '/en/browser-app',
      menuTitle: 'Browser App',
    },
  },
};
