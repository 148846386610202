import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    title: 'Zarządzanie plikami i dokumentami',
    description: (
      <>
        Nasza funkcja zarządzania plikami to jak cyfrowy sejf dla techników i
        menedżerów pest control. To miejsce, gdzie mogą bezpiecznie przechowywać
        wszystkie ważne dokumenty dotyczące firmy lub konkretnych placówek.
        Dzięki temu systemowi, wszystkie istotne informacje są dobrze
        zorganizowane, łatwo dostępne i chronione. <br></br>Oto co umożliwia
        nasz system plików:
      </>
    ),
    list: [
      {
        title: 'Porządek w dokumentach:',
        description:
          'Użytkownicy mogą przechowywać wszystkie swoje raporty, plany działania, mapy placówek i inne dokumenty w jednym, łatwo dostępnym miejscu.',
      },
      {
        title: 'Bezpieczne przechowywanie:',
        description:
          'Wszystkie pliki są zabezpieczone i chronione, więc nie trzeba martwić się o ich utratę czy dostęp osób trzecich.',
      },
      {
        title: 'Łatwy dostęp:',
        description:
          'Niezależnie od tego, gdzie się znajdujesz, masz dostęp do swoich plików – wystarczy połączenie z internetem.',
      },
      {
        title: 'Prosta organizacja:',
        description:
          'Możesz tworzyć foldery i kategorie, dzięki czemu nawet duża ilość dokumentów jest uporządkowana i łatwa do znalezienia.',
      },
      {
        title: 'Współdzielenie z innymi:',
        description:
          'Jeśli potrzebujesz podzielić się dokumentami z kolegami z zespołu lub klientami, możesz to zrobić szybko i bez problemów.',
      },
    ],
  },
  en: {
    title: 'File and Document Management',
    description: (
      <>
        Our file management feature is like a digital safe for pest control
        technicians and managers. It’s a place where they can safely store all
        important documents related to the company or specific facilities. With
        this system, all essential information is well organized, easily
        accessible, and protected. <br></br>Here’s what our file system enables:
      </>
    ),
    list: [
      {
        title: 'Document organization:',
        description:
          'Users can store all their reports, action plans, facility maps, and other documents in one easily accessible place.',
      },
      {
        title: 'Secure storage:',
        description:
          'All files are protected and secure, so there’s no need to worry about losing them or third-party access.',
      },
      {
        title: 'Easy access:',
        description:
          'No matter where you are, you have access to your files – all you need is an internet connection.',
      },
      {
        title: 'Simple organization:',
        description:
          'You can create folders and categories, so even a large number of documents is organized and easy to find.',
      },
      {
        title: 'Sharing with others:',
        description:
          'If you need to share documents with team members or clients, you can do it quickly and without any hassle.',
      },
    ],
  },
};

const FilesFunctionality = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style mb--60">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
                <ul className="list-icon">
                  {data.list.map((item, index) => (
                    <li key={index}>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      <span>
                        <span className="theme-primary">{item.title}</span>{' '}
                        {item.description}
                      </span>
                    </li>
                  ))}
                </ul>
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-right-content">
              <img src="/images/interactiveMap.png" alt="split Images" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilesFunctionality;
