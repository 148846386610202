import { initializeApp } from '@firebase/app';
import { getFirestore } from '@firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBO3dLecbxsmLCsusf29aSd1j_DdJvdUZE',
  authDomain: 'mousee-landing.firebaseapp.com',
  projectId: 'mousee-landing',
  storageBucket: 'mousee-landing.appspot.com',
  messagingSenderId: '460437072178',
  appId: '1:460437072178:web:6ae026571b6e53ff8f4f2b',
  measurementId: 'G-TQPMD4WK14',
};

// Inicjalizacja Firebase
const app = initializeApp(firebaseConfig);

// Inicjalizacja Firestore
const db = getFirestore(app);

export { db };
