import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { ROUTES } from '../../utils/routes';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    title: 'Skorzystaj z nowoczesnej aplikacji do zarządzania firmą DDD',
    subtitle:
      'Skontaktuj się z Nami i dowiedz się więcej o naszej aplikacji. Dzięki niej oszczędzisz czas i pieniądze.',
    btnText: 'Skontaktuj się z Nami',
  },
  en: {
    title: 'Are you ready for digital pest management?',
    subtitle:
      'Contact us and learn more about our application. With it, you will save time and money.',
    btnText: 'Contact us',
  },
};

const CalltoActionTwo = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div
      className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/home_warehouse1.png)`,
      }}
      data-black-overlay="8"
    >
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-10 offset-lg-1">
            <div className="inner">
              <div className="content text-center">
                <h2 className="title mb--0">{data.title}</h2>
                <p>{data.subtitle}</p>
                <div className="call-to-btn text-center">
                  <Link
                    className="btn-default btn-icon"
                    to={ROUTES[translation.selectedLang].contact.path}
                  >
                    {data.btnText}{' '}
                    <i className="icon">
                      <FiArrowRight />
                    </i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionTwo;
