import React from 'react';
// import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from 'react-icons/fi';
// import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const teamData = [
  {
    image:
      '/images/6479f1433c2d48676d157d3d_1606994439410.jpeg',
    name: 'Mateusz Kasprzak',
    designation: 'Founder & CEO',
    location: 'Wrocław, Polska',
    description: '',
    socialNetwork: [
      //   {
      //     icon: <FiFacebook />,
      //     url: '#',
      //   },
      //   {
      //     icon: <FiTwitter />,
      //     url: '#',
      //   },
      //   {
      //     icon: <FiInstagram />,
      //     url: '#',
      //   },
    ],
  },
  {
    image:
      '/images/64803486382f148958b65027_IMG-20230606-WA0003-p-500.jpg',
    name: 'Paweł Szymiczek',
    designation: 'COO',
    location: 'Wrocław, Polska',
    description: '',
    socialNetwork: [
      //   {
      //     icon: <FiFacebook />,
      //     url: '#',
      //   },
      //   {
      //     icon: <FiTwitter />,
      //     url: '#',
      //   },
      //   {
      //     icon: <FiInstagram />,
      //     url: '#',
      //   },
    ],
  },
  {
    image:
      '/images/6479f177f08a5a49e47aafce_347546794_2214071555648539_6544005579450361379_n-p-500.jpg',
    name: 'Angelo Giurano',
    designation: 'Lead Software Developer',
    location: 'Wrocław, Polska',
    description: '',
    socialNetwork: [
      //   {
      //     icon: <FiFacebook />,
      //     url: '#',
      //   },
      //   {
      //     icon: <FiTwitter />,
      //     url: '#',
      //   },
      //   {
      //     icon: <FiInstagram />,
      //     url: '#',
      //   },
    ],
  },
  {
    image:
      '/images/6480344fd0c2c3e2467b6268_Screenshot_2023-06-06-12-10-17-06_1c337646f29875672b5a61192b9010f9-p-500.jpg',
    name: 'Witek Gąsior',
    designation: 'Product Coordinator',
    location: 'Wrocław, Polska',
    description: '',
    socialNetwork: [
      //   {
      //     icon: <FiFacebook />,
      //     url: '#',
      //   },
      //   {
      //     icon: <FiTwitter />,
      //     url: '#',
      //   },
      //   {
      //     icon: <FiInstagram />,
      //     url: '#',
      //   },
    ],
  },
];

const TeamFour = ({ column, teamStyle }) => {
  return (
    <div className="row row--15">
      {teamData.map((data, index) => (
        <div className={`${column}`} key={index}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`rn-team ${teamStyle}`}>
              <div className="inner">
                <figure className="thumbnail">
                  <div
                    style={{
                      backgroundImage: `url(${data.image})`,
                      width: '100%',
                      backgroundColor: 'red',
                      aspectRatio: '1 / 1',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      borderRadius: 20,
                    }}
                  ></div>
                </figure>
                <figcaption className="content">
                  <div className="team-info">
                    <h2 className="title">{data.name}</h2>
                    <h6 className="subtitle theme-primary">
                      {data.designation}
                    </h6>
                    <div className="team-form">
                      <span className="location">{data.location}</span>
                    </div>
                  </div>
                </figcaption>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default TeamFour;
