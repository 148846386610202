import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SectionTitle from '../sectionTitle/SectionTitle';

const PricingTwo = () => {
  return (
    <div>
      <div className="rn-service-area rn-section-gap ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle={'Dostosuj pakiet do swoich potrzeb'}
                // subtitle={translations.mainProducts.subtitle}
                title={'Cennik'}
                description="Zapoznaj się z naszym wyborem planów w atrakcyjnych cenach. Wybierz opcję, która najlepiej spełnia Twoje potrzeby i oczekiwania."
              />
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <div className="row row--1 justify-content-center align-items-center">
        <div className="col-lg-3 col-md-6 col-12">
          <div className="rn-pricing style-2">
            <div className="pricing-table-inner">
              <div className="pricing-header">
                <h4 className="title">Premium</h4>
                <div className="pricing">
                  <div className="price-wrapper">
                    <span className="currency">zł</span>
                    <span className="price">799</span>
                  </div>
                  <span className="subtitle">za miesiąc</span>
                </div>
              </div>
              <div className="pricing-body">
                <ul className="list-style--1">
                  <li>
                    <FiCheck /> Interwencje
                  </li>
                  <li>
                    <FiCheck /> Rekomendacje
                  </li>
                  <li>
                    <FiCheck /> 10 klientów
                  </li>
                  <li>
                    <FiCheck /> Przeglądy
                  </li>
                  <li>
                    <FiCheck /> Nieograniczona ilość uzytkowników
                  </li>
                </ul>
              </div>
              <div className="pricing-footer">
                <Link className="btn-default" to="/contact">
                  Kontakt
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* End PRicing Table Area  */}
        {/* Start PRicing Table Area  */}
        <div className="col-lg-3 col-md-6 col-12">
          <div className="rn-pricing style-2">
            <div className="pricing-table-inner">
              <div className="pricing-header">
                {/* <h4 className="title">Indywidualny</h4> */}
                <div className="pricing">
                  <div className="price-wrapper">
                    <span className="currency"></span>
                    <span className="price" style={{ fontSize: '35px' }}>
                      Indywidualna <br />
                      wycena
                    </span>
                  </div>
                  <span className="subtitle"></span>
                </div>
              </div>
              <div className="pricing-body">
                Jezeli aktualne pakiety nie spełniają Twoich wymagań skontaktuj
                się z nami. Dołozymy wszelkich starań, aby spełnić Twoje
                oczekiwania
              </div>
              <div className="pricing-footer">
                <Link className="btn-default" to="/contact">
                  Kontakt
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* End PRicing Table Area  */}
      </div>
    </div>
  );
};
export default PricingTwo;
