import { useState, useRef } from 'react';
import { FiMenu } from 'react-icons/fi';
import Logo from '../../elements/logo/Logo';
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import useStickyHeader from './useStickyHeader';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { ROUTES } from '../../utils/routes';
import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const HeaderOne = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const ref = useRef();

  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && false ? 'sticky' : ''}`;

  const location = useLocation();
  const x = location.pathname.split('/');
  let isEn = false;
  if (x[1] === 'en') {
    isEn = true;
  }
  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-4">
              <Logo
                image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                image2={`${process.env.PUBLIC_URL}/images/logo/logo-white.png`}
              />
            </div>
            <div className="col-lg-9 col-md-6 col-8 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
                <div className="header-btn">
                  {isEn && (
                    <Link
                      to={ROUTES.pl.home.path}
                      className={styles.langSelector}
                    >
                      <img
                        src={require('../../assets/images/pl-flag.png')}
                        alt="polish"
                      />
                    </Link>
                  )}
                  {!isEn && (
                    <Link
                      to={ROUTES.en.home.path}
                      className={styles.langSelector}
                    >
                      <img
                        src={require('../../assets/images/en-flag.png')}
                        alt="English"
                      />
                    </Link>
                  )}
                  {isEn && (
                    <Link
                      className={`btn-default ${btnStyle}`}
                      to={ROUTES.en.contact.path}
                    >
                      DEMO
                    </Link>
                  )}
                  {!isEn && (
                    <Link
                      className={`btn-default ${btnStyle}`}
                      to={ROUTES.pl.contact.path}
                    >
                      DEMO
                    </Link>
                  )}
                </div>
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <span
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderOne;
