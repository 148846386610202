import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from '../../context/useTranslations';
import { ROUTES } from '../../utils/routes';
const translations = {
  pl: {
    privacyPolicy: 'Polityka prywatności',
    contact: 'Kontakt',
  },
  en: {
    privacyPolicy: 'Privacy Policy',
    contact: 'Contact',
  },
};
const Copyright = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;

  return (
    <div className="copyright-area copyright-style-one">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-8 col-sm-12 col-12">
            <div className="copyright-left">
              <ul className="ft-menu link-hover">
                <li>
                  <Link
                    to={ROUTES[translation.selectedLang].privacyPolicy.path}
                  >
                    {data.privacyPolicy}
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES[translation.selectedLang].contact.path}>
                    {data.contact}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-12 col-12">
            <div className="copyright-right text-center text-md-end">
              <p className="copyright-text">
                © Insee Solutions {new Date().getFullYear()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Copyright;
