import React from 'react';
import { NavLink as Link } from 'react-router-dom';

import { ROUTES } from '../../utils/routes';
import { useTranslation } from '../../context/useTranslations';

const Nav = () => {
  const x = useTranslation();
  const langRoutes = ROUTES[x.selectedLang];
  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link to={langRoutes.home.path} activeClassName="active" exact>
          {langRoutes.home.menuTitle}
        </Link>
      </li>

      <li>
        <Link to={langRoutes.aboutUs.path} activeClassName="active">
          {langRoutes.aboutUs.menuTitle}
        </Link>
      </li>
      <li className="has-droupdown ">
        <Link to={langRoutes.browserApp.path} activeClassName="active">
          {langRoutes.browserApp.menuTitle}
        </Link>
      </li>

      <li className="has-droupdown">
        <Link to={langRoutes.mousee.path} activeClassName="active">
          {langRoutes.mousee.menuTitle}
        </Link>
      </li>

      {/* <li className="has-droupdown">
        <Link to={langRoutes.pricing.path} activeClassName="active">
          Cennik
        </Link>
      </li> */}
      <li className="has-droupdown">
        <Link to={langRoutes.contact.path} activeClassName="active">
          {langRoutes.contact.menuTitle}
        </Link>
      </li>
    </ul>
  );
};
export default Nav;
