import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import CalltoActionSeven from '../../elements/calltoaction/CalltoActionSeven';
import ScrollTop from './ScrollTop';
import { FiFacebook, FiLinkedin } from 'react-icons/fi';
import { useTranslation } from '../../context/useTranslations';
const menuOneTranslations = {
  pl: {
    id: 2,
    title: '',
    quicklink: [
      {
        id: 1,
        text: 'O nas',
        url: ROUTES.pl.aboutUs.path,
      },
      // {
      //   id: 2,
      //   text: 'Cennik',
      //   url: ROUTES.pl.pricing.path,
      // },
      {
        id: 3,
        text: 'Kontakt',
        url: ROUTES.pl.contact.path,
      },
    ],
  },
  en: {
    id: 2,
    title: '',
    quicklink: [
      {
        id: 1,
        text: 'About',
        url: ROUTES.en.aboutUs.path,
      },
      // {
      //   id: 2,
      //   text: 'Pricing',
      //   url: '/pricing',
      // },
      {
        id: 3,
        text: 'Contact',
        url: ROUTES.en.contact.path,
      },
    ],
  },
};

const menuTwoTranslations = {
  pl: {
    id: 3,
    title: 'Produkty',
    stayWithUs: 'Zostań z Nami',
    quicklink: [
      {
        id: 1,
        text: 'Aplikacja przeglądarkowa',
        url: ROUTES.pl.browserApp.path,
        // url: ROUTES.pl.browserApp.path,
      },
      {
        id: 2,
        text: 'Mousee',
        url: ROUTES.pl.mousee.path,
        // url: ROUTES.pl.mousee.path,
      },
    ],
  },
  en: {
    id: 3,
    title: 'Products',
    stayWithUs: 'Stay With Us',
    quicklink: [
      {
        id: 1,
        text: 'Browser application',
        url: ROUTES.en.browserApp.path,
        // url: ROUTES.en.browserApp.path,
      },
      {
        id: 2,
        text: 'Mousee',
        url: ROUTES.en.mousee.path,
        // url: ROUTES.en.mousee.path,
      },
    ],
  },
};

const FooterTwo = () => {
  const translation = useTranslation();
  const menuOne =
    menuOneTranslations[translation.selectedLang] || menuOneTranslations.pl;
  const menuTwo =
    menuTwoTranslations[translation.selectedLang] || menuTwoTranslations.pl;
  return (
    <>
      <footer className="rn-footer footer-style-default variation-two">
        <CalltoActionSeven />
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title">Insee Solutions</h4>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      {menuOne.quicklink.map((data, index) => (
                        <li key={index}>
                          <Link to={`${data.url}`}>{data.text}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">{menuTwo.title}</h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {menuTwo.quicklink.map((data, index) => (
                          <li key={index}>
                            <Link to={`${data.url}`}>{data.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12"></div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12"></div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title">{menuTwo.stayWithUs}</h4>
                  <div className="inner">
                    <h6 className="subtitle"> </h6>
                    <ul className="social-icon social-default justify-content-start">
                      <li>
                        <a href="https://www.facebook.com/insee.solutions">
                          <FiFacebook />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/inseesolutions/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FiLinkedin />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterTwo;
