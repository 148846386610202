import React from 'react';
import Layout from '../common/Layout';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { ROUTES } from '../utils/routes';
import { useTranslation } from '../context/useTranslations';

const translations = {
  pl: {
    title: 'Podana strona nie istnieje',
    subtitle: 'Zmień adresu URL lub wróć na stronę główną',
    homePage: 'Strona główna',
  },
  en: {
    title: 'Page not found',
    subtitle: 'Change the URL or go back to the homepage',
    homePage: 'Homepage',
  },
};

const Page404 = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  const homeHref = ROUTES[translation.selectedLang].home.path;
  return (
    <Layout>
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <div className="error-inner">
                <h2 className="title">{data.title}</h2>
                <p className="description">{data.subtitle}</p>
                <div className="view-more-button mb--50">
                  <Link className="btn-default" to={homeHref}>
                    {data.homePage}
                  </Link>
                </div>
                <img src="/images/404.png" alt="404" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Page404;
