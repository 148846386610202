import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    list: [
      {
        id: '1',
        title: 'Innowacje',
        description: 'Zaawansowane technologie w ochronie przed szkodnikami.',
      },
      {
        id: '2',
        title: 'Efektywność',
        description: 'Inteligentne rozwiązania zapewniające ciągłą ochronę.',
      },
      {
        id: '3',
        title: 'Rozwiązania',
        description: 'Skuteczne metody kontroli dostosowane do potrzeb.',
      },
      {
        id: '4',
        title: 'Proces',
        description: 'Najnowsze innowacje ulepszające pest control.',
      },
    ],
  },
  en: {
    list: [
      {
        id: '1',
        title: 'Innovations',
        description: 'Advanced technologies in pest control.',
      },
      {
        id: '2',
        title: 'Efficiency',
        description: 'Intelligent solutions providing continuous protection.',
      },
      {
        id: '3',
        title: 'Solutions',
        description: 'Effective control methods tailored to your needs.',
      },
      {
        id: '4',
        title: 'Process',
        description: 'The latest innovations improving pest control.',
      },
    ],
  },
};
const TimelineTwo = ({ classVar }) => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="timeline-style-two bg-color-blackest">
      <div className="row row--0">
        {/* Start Single Progress */}
        {data.list.map((data, index) => (
          <div
            className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`}
            key={index}
          >
            <div className="rn-timeline">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h6 className="title">{data.title}</h6>
              </ScrollAnimation>
              <div className="progress-line">
                <div className="line-inner"></div>
              </div>
              <div className="progress-dot">
                <div className="dot-level">
                  <div className="dot-inner"></div>
                </div>
              </div>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
              </ScrollAnimation>
            </div>
          </div>
        ))}
        {/* End Single Progress */}
      </div>
    </div>
  );
};
export default TimelineTwo;
