import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    title: 'Analityka',
    description: (
      <>
        Analityka stanowi{' '}
        <span className="theme-primary">serce innowacyjnego</span> systemu
        zarządzania pest control, oferując użytkownikom możliwość głębokiego
        zrozumienia i optymalizacji swoich działań. Przedstawiając{' '}
        <span className="theme-primary">interaktywne wykresy i statystyki</span>{' '}
        dotyczące przeprowadzonych akcji, jak czyszczenie sprzętu czy wymiana
        wabików, Analityka przekształca surowe dane w praktyczną wiedzę,
        umożliwiając podejmowanie bardziej świadomych decyzji.
      </>
    ),
    list: [
      {
        title: 'Zrozumienie danych:',
        description:
          'Łatwa w interpretacji wizualizacja danych pozwala na szybkie identyfikowanie trendów i anomalii w aktywności szkodników.',
      },
      {
        title: 'Dokładność informacji:',
        description:
          'System dokładnie rejestruje ilość i rodzaj schwytanych szkodników, co umożliwia szczegółową analizę skuteczności poszczególnych metod.',
      },
      {
        title: 'Optymalizacja działań:',
        description:
          'Analityka wskazuje, które metody kontroli szkodników są najbardziej efektywne, co pozwala na lepsze alokowanie zasobów.',
      },
      {
        title: 'Historia interwencji:',
        description:
          'Każda akcja jest dokumentowana, tworząc historię interwencji, która może służyć jako baza wiedzy i doświadczeń.',
      },
      {
        title: 'Prognozowanie i zapobieganie:',
        description:
          'Na podstawie zgromadzonych danych, Analityka może pomóc w prognozowaniu pojawienia się szkodników, umożliwiając zapobieganie zanim problem się pojawi.',
      },
    ],
  },
  en: {
    title: 'Analytics',
    description: (
      <>
        Analytics is the{' '}
        <span className="theme-primary">heart of the innovative</span> pest
        control management system, offering users the ability to deeply
        understand and optimize their activities. By presenting{' '}
        <span className="theme-primary">interactive charts and statistics</span>{' '}
        about actions taken, such as equipment cleaning or bait replacement,
        Analytics transforms raw data into practical knowledge, enabling more
        informed decision-making.
      </>
    ),
    list: [
      {
        title: 'Understanding data:',
        description:
          'Easily interpretable data visualization allows for quick identification of pest activity trends and anomalies.',
      },
      {
        title: 'Information accuracy:',
        description:
          'The system accurately records the quantity and type of pests caught, enabling detailed analysis of the effectiveness of individual methods.',
      },
      {
        title: 'Activity optimization:',
        description:
          'Analytics indicates which pest control methods are most effective, allowing for better resource allocation.',
      },
      {
        title: 'Intervention history:',
        description:
          'Each action is documented, creating a history of interventions that can serve as a knowledge and experience base.',
      },
      {
        title: 'Forecasting and prevention:',
        description:
          'Based on the data collected, Analytics can help predict the appearance of pests, enabling prevention before the problem arises.',
      },
    ],
  },
};

const AnalyticsFunctionality = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style mb--60">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
                <ul className="list-icon">
                  {data.list.map((item, index) => (
                    <li key={index}>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      <span>
                        <span className="theme-primary">{item.title}</span>:{' '}
                        {item.description}
                      </span>
                    </li>
                  ))}
                </ul>
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-right-content">
              <img src="/images/analytics.png" alt="split Images" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsFunctionality;
