import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from '../../context/useTranslations';

const Data = {
  pl: [
    {
      countNum: 25,
      countTitle: 'Zadowolonych klientów',
    },
    {
      countNum: 16,
      countTitle: 'Funkcjonalności',
    },

    {
      countNum: 5000,
      countTitle: 'Złapanych szkodników',
    },
  ],
  en: [
    {
      countNum: 25,
      countTitle: 'Satisfied customers',
    },
    {
      countNum: 16,
      countTitle: 'Functionalities',
    },
    {
      countNum: 100,
      countTitle: 'Ideas for new functionalities',
    },
    {
      countNum: 500,
      countTitle: 'Caught pests',
    },
  ],
};
const CounterUpFour = ({ textALign, counterStyle, column }) => {
  const translation = useTranslation();
  const langData = Data[translation.selectedLang] || Data.pl;
  return (
    <div className="row">
      {langData.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`count-box ${counterStyle} ${textALign}`}>
            <TrackVisibility once>
              {({ isVisible }) =>
                isVisible && (
                  <div className="count-number">
                    {isVisible ? <CountUp end={data.countNum} /> : 0}
                  </div>
                )
              }
            </TrackVisibility>
            <h5 className="counter-title">{data.countTitle}</h5>
          </div>
        </div>
      ))}
    </div>
  );
};
export default CounterUpFour;
