import React from 'react';
import SEO from '../../common/SEO';
import { FiCheck } from 'react-icons/fi';

import HeaderOne from '../../common/header/HeaderOne';
import FooterTwo from '../../common/footer/FooterTwo';
import Copyright from '../../common/footer/Copyright';

import Separator from '../../elements/separator/Separator';

import WhatIsMousee from './WhatIsMousee';
import MouseeOne from './MouseeOne';
import MouseeUniversal from './MouseeUniversal';
import MouseeInsects from './MouseeInsects';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    seo: {
      title: 'Mousee - Elektroniczne Detektory - Insee Solutions',
      description:
        'Mousee to inteligentne urządzenia elektroniczne, które umożliwiają monitorowanie i kontrolę szkodników w czasie rzeczywistym. Dzięki Mousee, zarządzanie pułapkami jest szybkie i bezproblemowe.',
      keywords:
        'iot, detektor elektroniczny, mousee, insee, aplikacja mobilna, aplikacja przeglądarkowa, urządzenia IOT, zarządzanie szkodnikami, DDD, deratyzacja, dezynfekcja, dezynsekcja, oprogramowanie dla firm, aplikacja dla firm, aplikacja dla firm DDD, aplikacja dla firm deratyzacyjnych, aplikacja dla firm dezynfekcyjnych, aplikacja dla firm dezynsekcyjnych, nfc, qrcode, qr, iot',
    },
    smallTitle: 'Inteligentne urządzenia zdalnej detekcji',
    title: 'Mousee',
    list: [
      {
        title: 'Wszechstronność',
        description:
          'Mousee pasuje do każdej pułapki, ułatwiając kontrolę nad szkodnikami w każdym miejscu.',
      },
      {
        title: 'Łatwość użycia',
        description:
          'Proste w montażu Mousee sprawiają, że zarządzanie pułapkami jest szybkie i bezproblemowe.',
      },
      {
        title: 'Powiadomienia w czasie rzeczywistym',
        description:
          'Mousee wysyłają automatyczne alerty, pozwalając na błyskawiczną reakcję.',
      },
    ],
    docs: {
      title: 'Cyfrowa Dokumentacja DDD',
      description:
        'Cyfrowa Dokumentacja DDD to rewolucja w branży deratyzacji, dezynfekcji i dezynsekcji, przenosząc cały proces dokumentowania interwencji do szybkiego i wydajnego środowiska online. Dzięki przechowywaniu danych w chmurze, wszelkie protokoły i raporty są dostępne w każdej chwili i z każdego miejsca, eliminując potrzebę stosowania papierowych formularzy. To nowoczesne rozwiązanie nie tylko przyspiesza pracę techników DDD, ale także zwiększa dokładność i bezpieczeństwo przechowywanych informacji.',
    },
  },
  en: {
    seo: {
      title: 'Mousee - Electronic Detectors - Insee Solutions',
      description:
        'Mousee is an intelligent electronic device that allows real-time monitoring and control of pests. With Mousee, trap management is quick and hassle-free.',
      keywords:
        'mobile application, browser application, IOT devices, pest management, Pest control, deratization, disinfection, pest control, software for companies, application for companies, pest control application for companies, deratization application for companies, disinfection application for companies, pest control application for companies, nfc, qrcode, qr, iot',
    },
    smallTitle: 'Intelligent electronic devices',
    title: 'Mousee',
    list: [
      {
        title: 'Versatility',
        description:
          'Mousee fits into any trap, making it easy to control pests in any location.',
      },
      {
        title: 'Ease of use',
        description:
          'Easy-to-install Mousee make trap management quick and hassle-free.',
      },
      {
        title: 'Real-time notifications',
        description:
          'Mousee send automatic alerts, allowing for lightning-fast reactions.',
      },
    ],
    docs: {
      title: 'Digital Pest Control Documentation',
      description:
        'Digital Pest Control Documentation is a revolution in the pest control, disinfection and disinsection industry, moving the entire intervention documentation process to a fast and efficient online environment. By storing data in the cloud, all protocols and reports are available at any time and from any place, eliminating the need for paper forms. This modern solution not only speeds up the work of pest control technicians, but also increases the accuracy and security of stored information.',
    },
  },
};

const Mousee = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <>
      <SEO
        title={data.seo.title}
        description={data.seo.description}
        keywords={data.seo.keywords}
      />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-750">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <span className="subtitle">{data.smallTitle}</span>
                  <h1 className="title theme-primary display-one">
                    {data.title}
                  </h1>
                  <ul className="list-icon">
                    {data.list.map((item, index) => (
                      <li key={index}>
                        <span className="icon">
                          <FiCheck />
                        </span>{' '}
                        {item.title}: {item.description}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-xl-6 order-1 order-lg-2">
                <div className="frame-image">
                  <img src="/images/beforeAfterInsee.png" alt="Insee" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <WhatIsMousee />
        <MouseeOne />
        <MouseeInsects />
        <MouseeUniversal />

        <Separator />
        {/* Start Service Area  */}

        {/* End Service Area  */}
        {/* Start Service Area  */}
        <div className="service-area rn-section-gapBottom mt--100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">{data.docs.title}</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">{data.docs.description}</p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default Mousee;
