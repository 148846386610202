import React from 'react';
import {
  FiMap,
  FiBarChart,
  FiFolder,
  FiPhone,
  FiWifi,
  FiCalendar,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    list: [
      {
        icon: <FiBarChart />,
        title: 'Analityka',
        description:
          'Analiza trendów podjętych akcji i schwytanych szkodników ułatwi wyeliminowanie problemu z każdej placówki',
      },
      {
        icon: <FiFolder />,
        title: 'Raporty',
        description:
          'Ręczne tworzenie raportu podczas inspekcji obiektu jest uciążliwe – platforma Insee generuje raport automatycznie po zakończeniu inspekcji obiektu w aplikacji Insee.',
      },
      {
        icon: <FiMap />,
        title: 'Interaktywny rzut obiektu',
        description:
          'Wgraj rzut obiektu, umieść na nim wszystkie urządzenia monitorujące obiekt. Twój klient ma stały podgląd dokładnych lokalizacji wszystkich punktów kontrolnych.',
      },
      {
        icon: <FiCalendar />,
        title: 'Harmonogram',
        description:
          'Zaplanuj przeglądy i działania w aplikacji Insee za pomocą kalendarza. Dzięki temu nie zapomnisz o żadnym obiekcie i zawsze będziesz mieć podgląd nad harmonogramem.',
      },
      {
        icon: <FiPhone />,
        title: 'Aplikacja mobilna',
        description:
          'Nasza aplikacja mobilna jest dostępna w Sklep Play Android i App Store iOS. Dzięki niej możesz skanować kody QR i NFC i szybciej dostarczać informację odnośnie urządzeń',
      },
      {
        icon: <FiWifi />,
        title: 'Urządzenia elektroniczne',
        description:
          'Zainstaluj urządzenia elektroniczne w obiekcie, które będą monitorować obiekt 24/7. Dzięki nim będziesz mógł szybko zareagować na pojawienie się szkodników.',
      },
    ],
  },
  en: {
    list: [
      {
        icon: <FiBarChart />,
        title: 'Analytics',
        description:
          'Analyzing trends of actions taken and pests caught will help eliminate the problem from each facility',
      },
      {
        icon: <FiFolder />,
        title: 'Reports',
        description:
          'Manually creating a report during an object inspection is cumbersome - the Insee platform generates a report automatically after the object inspection is completed in the Insee application.',
      },
      {
        icon: <FiMap />,
        title: 'Interactive object projection',
        description:
          'Upload an object projection, place all devices monitoring the object on it. Your client has a constant view of the exact locations of all control points.',
      },
      {
        icon: <FiCalendar />,
        title: 'Schedule',
        description:
          'Plan inspections and actions in the Insee application using the calendar. This way you will not forget about any object and you will always have a view of the schedule.',
      },
      {
        icon: <FiPhone />,
        title: 'Mobile application',
        description:
          'Our mobile application is available in the Android Play Store and iOS App Store. With it, you can scan QR and NFC codes and deliver information about devices faster',
      },
      {
        icon: <FiWifi />,
        title: 'Electronic devices',
        description:
          'Install electronic devices in the facility that will monitor the facility 24/7. Thanks to them, you will be able to quickly respond to the appearance of pests.',
      },
    ],
  },
};

const ServiceOne = ({ textAlign, serviceStyle }) => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="row row--15 service-wrapper">
      {data.list.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h4 className="title w-600">
                  <Link
                    to="#service"
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h4>
                <p
                  className="description b1 color-gray mb--0"
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
