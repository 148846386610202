import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Typed from 'react-typed';
// import Image from '../../assets/images/warehouse_from_top.png';
import { useTranslation } from '../../context/useTranslations';
import { ROUTES } from '../../utils/routes';
import { FiCheck } from 'react-icons/fi';

const translations = {
  pl: {
    readMore: 'Czytaj więcej',
    title: 'Zdalne detektory ',
    text: 'Innowacyjne rozwiązanie, wprowadzające nowoczesną technologię w dziedzinę działań DDD. Detektory Mousee monitorują pułapkę 24/7, a w przypadku pojawienia się szkodnika – system przesyła alert o tym fakcie do wskazanych osób. Oferujemy również szczegółowe i kompleksowe cyfrowe raporty i analizy.',
    list: [
      'Detekcja 24/7',
      'Powiadomienia mobilne',
      'Prosta instalacja',
      '3 lata pracy na baterii',
      '15zł / miesięcznie',
      'Prosta umowa',
    ],
  },
  en: {
    readMore: 'Read More',
    title: 'IoT Electronic device',
    text: 'An innovative solution that introduces modern technology into the field of pest control activities. Mousee detectors monitor the trap 24/7, and in case of the appearance of a pest - the system sends an alert about this fact to the designated persons. We also offer detailed and comprehensive digital reports and analyzes.',
    list: [
      '24/7 detection',
      'Mobile notifications',
      'Easy installation',
      '3 years of battery life',
      '15zł / monthly',
      'Simple contract',
    ],
  },
};
const AboutOne = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                  {data.title}
                  <br />{' '}
                  <Typed
                    className="theme-primary"
                    strings={['Mousee']}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  />
                </h2>
                <p>{data.text}</p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-icon">
                      {data.list.slice(0, 3).map((item, index) => (
                        <li key={index}>
                          <span className="icon">
                            <FiCheck />
                          </span>{' '}
                          <span dangerouslySetInnerHTML={{ __html: item }} />
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-icon">
                      {data.list.slice(3, 10).map((item, index) => (
                        <li key={index}>
                          <span className="icon">
                            <FiCheck />
                          </span>{' '}
                          <span dangerouslySetInnerHTML={{ __html: item }} />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="read-more-btn mt--40">
                  <Link
                    className="btn-default"
                    to={ROUTES[translation.selectedLang].mousee.path}
                  >
                    <span>{data.readMore}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                style={{ borderRadius: 10 }}
                // src={Image}
                src="/images/mouseeUniversal.png"
                alt="About Mousee"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
