import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from '../../context/useTranslations';

const translations = {
  pl: {
    title: 'Harmonogram',
    description: (
      <>
        Nasz kalendarz/harmonogram to jak osobisty asystent dla techników
        zajmujących się pest control. Dzięki niemu każdy dzień pracy jest jasno
        zaplanowany, a wszystkie zadania są uporządkowane i gotowe do
        realizacji. To narzędzie pozwala na skuteczną organizację czasu, dzięki
        czemu technicy mogą efektywniej zarządzać swoimi obowiązkami i mieć
        pewność, że żaden ważny punkt nie zostanie pominięty. Oto co oferuje
        nasz kalendarz:
      </>
    ),
    list: [
      {
        title: 'Planowanie zadań:',
        description:
          'Technicy mogą ustawić harmonogram interwencji, takich jak instalacja pułapek czy przeglądy, i mieć pewność, że każda ważna czynność jest zaplanowana.',
      },
      {
        title: 'Przypomnienia:',
        description:
          'Kalendarz automatycznie przypomni o zbliżających się zadaniach, więc technicy nie muszą martwić się o pamiętanie wszystkiego.',
      },
      {
        title: 'Elastyczność:',
        description:
          'Zmiany w harmonogramie? Żaden problem. Technicy mogą łatwo dopasować swoje plany i reagować na niespodziewane zdarzenia.',
      },
      {
        title: 'Dostępność:',
        description:
          'Kalendarz jest dostępny z każdego urządzenia, więc technicy mają do niego dostęp w terenie, co jest nieocenione w pracy mobilnej.',
      },
      {
        title: 'Synchronizacja:',
        description:
          'Jeśli jest więcej techników, kalendarz może synchronizować ich plany, dzięki czemu współpraca jest łatwiejsza i bardziej efektywna.',
      },
    ],
  },
  en: {
    title: 'Calendar',
    description: (
      <>
        Our calendar is like a personal assistant for pest control technicians.
        It helps them plan their workday and organize tasks, so they can manage
        their duties more effectively and be sure that no important point is
        missed. Here’s what our calendar offers:
      </>
    ),
    list: [
      {
        title: 'Task planning:',
        description:
          'Technicians can set a schedule for interventions, such as trap installation or inspections, and be sure that every important activity is planned.',
      },
      {
        title: 'Reminders:',
        description:
          'The calendar will automatically remind about upcoming tasks, so technicians don’t have to worry about remembering everything.',
      },
      {
        title: 'Flexibility:',
        description:
          'Changes in the schedule? No problem. Technicians can easily adjust their plans and react to unexpected events.',
      },
      {
        title: 'Availability:',
        description:
          'The calendar is accessible from any device, so technicians have access to it in the field, which is invaluable in mobile work.',
      },
      {
        title: 'Synchronization:',
        description:
          'If there are more technicians, the calendar can synchronize their plans, making cooperation easier and more effective.',
      },
    ],
  },
};

const CalendarFunctionality = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;
  return (
    <div className="rn-splite-style mb--60">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img src="/images/calendar.png" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{data.title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
                <ul className="list-icon">
                  {data.list.map((item, index) => (
                    <li key={index}>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      <span>
                        <span className="theme-primary">{item.title}</span>{' '}
                        {item.description}
                      </span>
                    </li>
                  ))}
                </ul>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarFunctionality;
