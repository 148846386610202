import React from 'react';
import { FiCheck } from 'react-icons/fi';

const AdvancePricingOne = () => {
  return (
    <div className="advance-pricing">
      <div className="inner">
        <div className="row row--0">
          <div className="col-lg-6">
            <div className="pricing-left">
              <h3 className="main-title">Medium</h3>
              <p className="description">Dostosowany do większości firm</p>
              <div className="price-wrapper">
                <span className="price-amount">
                  399zł <sup>/miesiąc</sup>
                </span>
              </div>
              <div className="pricing-btn-group">
                <button className="btn-default">Skontaktuj się</button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="pricing-right">
              <div className="pricing-offer">
                <div className="single-list">
                  <h4 className="price-title">Limity</h4>
                  <ul className="plan-offer-list">
                    <li>
                      <FiCheck /> 1500 urządzeń
                    </li>
                    <li>
                      <FiCheck /> 15 klientów
                    </li>
                    <li>
                      <FiCheck /> Nielimitowana ilość placówek
                    </li>
                    <li>
                      <FiCheck /> Przeglądy
                    </li>
                    <li>
                      <FiCheck /> Statystyki
                    </li>
                    <li>
                      <FiCheck /> Interwencje
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancePricingOne;
