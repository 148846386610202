import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import ContactOne from '../elements/contact/ContactOne';
import { useTranslation } from '../context/useTranslations';
const translations = {
  pl: {
    title: 'Skontaktuj się z Nami',
    subtitle: 'Formularz kontaktowy',
    seo: {
      title: 'Kontakt - Insee Solutions',
      description:
        'Skontaktuj się z nami, aby dowiedzieć się więcej o naszych usługach.',
    },
  },
  en: {
    title: 'Contact Us',
    subtitle: 'Contact Form',
    seo: {
      title: 'Contact - Insee Solutions',
      description: 'Contact us to learn more about our services.',
    },
  },
};
const Contact = () => {
  const translation = useTranslation();
  const data = translations[translation.selectedLang] || translations.pl;

  return (
    <>
      <SEO title={data.seo.title} description={data.seo.description} />
      <Layout>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle={data.subtitle}
                    title={data.title}
                    description=""
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Contact;
